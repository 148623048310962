import React, { Component } from "react";
import { Card, CardBody, Label, Col, Row } from "reactstrap";
import moment from "moment";
import Icon from "react-icons-kit";
import { withRouter } from "react-router-dom";
import { pencil } from "react-icons-kit/typicons";
import { PopUp, Title, Button } from "../../";
import { Formik } from "formik";
import * as Yup from "yup";

class ClientCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prodDetails: {},
            modal: false,
        };
    }

    timeSlot = (slot) => {
        return slot === 1 ? "11AM - 1PM" : slot === 2 ? "3PM - 5PM" : "7PM - 9PM";
    };

    formatDate = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };

    toggle = () => {
        this.setState((prevState) => ({
            modal: !prevState.modal,
        }));
    };

    requestUpdatePoints = (values) => {
        const { prodDetails } = this.props;
        const payload = {
            UserId: prodDetails.userInfo.userId,
            Points: values.points,
        };
        this.props.requestUpdatePoints(payload);
        this.toggle();
    };

    render() {
        const { prodDetails } = this.props;
        const { modal } = this.state;
        if (prodDetails) {
            return (
                <div className="prod-card-container">
                    <PopUp visible={modal} toggle={this.toggle} className="corp-modal-pop-up">
                        <div className="popUp_container">
                            <Title title="Update Points" />
                            <div className="yellow_popupbg" />
                            <div className="blue_popupbg" />
                            <div className="popUp_content">
                                <Formik
                                    initialValues={{
                                        points: prodDetails.userInfo.points,
                                    }}
                                    validationSchema={Yup.object().shape({
                                        points: Yup.string().required("Required"),
                                    })}
                                    onSubmit={(values) => {
                                        this.requestUpdatePoints(values);
                                    }}>
                                    {(props) => {
                                        const { handleBlur, handleChange, isSubmitting, handleSubmit, errors, touched, values } = props;
                                        return (
                                            <form onSubmit={handleSubmit} className="formStyle">
                                                <input
                                                    type="number"
                                                    id="points"
                                                    name="points"
                                                    value={values.points}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.points && touched.points ? "text-input error" : "text-input"}
                                                />
                                                {errors.points && <div className="input-feedback">{errors.points}</div>}
                                                <div className="button-container">
                                                    <Button type="submit" title="UPDATE" disabled={isSubmitting} />
                                                    <Button type="button" title="CANCEL" outline onClick={this.toggle} />
                                                </div>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </PopUp>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <Label>ORDER NUMBER</Label>
                                    <p>{prodDetails.orderNumber}</p>
                                </Col>
                                <Col>
                                    <Label>ECP NUMBER</Label>
                                    <p>{prodDetails.ecpId}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>ECP NAME</Label>
                                    <p>{prodDetails.ecpName}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="column-2">
                                    <Row>
                                        <Col>
                                            <Label>NAME OF OWNER</Label>
                                            <p>{prodDetails.ecpId}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>DATE OF BIRTH</Label>
                                            <p>{prodDetails.ecpId}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="no-border">
                                            <Label>DATE OF PURCHASED</Label>
                                            <p>{prodDetails.ecpId}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="column-2">
                                    <Row>
                                        <Col>
                                            <Label>RL TYPE</Label>
                                            <p>RIGHT</p>
                                        </Col>
                                        <Col>
                                            <Label>RL TYPE</Label>
                                            <p>LEFT</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>SPH</Label>
                                            <p>{prodDetails.rightResult.sph}</p>
                                        </Col>
                                        <Col>
                                            <Label>SPH</Label>
                                            <p>{prodDetails.leftResult.sph}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="no-border">
                                            <Label>CYL</Label>
                                            <p>{prodDetails.rightResult.cyl}</p>
                                        </Col>
                                        <Col className="no-border">
                                            <Label>CYL</Label>
                                            <p>{prodDetails.leftResult.cyl}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>CONTACT NAME</Label>
                                    <p>{prodDetails.userInfo.userName}</p>
                                </Col>
                                <Col>
                                    <Label>CONTACT NUMBER</Label>
                                    <p>{prodDetails.userInfo.mobileNumber}</p>
                                </Col>
                                <Col>
                                    <Label>POINTS</Label>
                                    <p className="edit">
                                        {prodDetails.userInfo.points}
                                        <Icon icon={pencil} style={{ marginLeft: 10 }} onClick={this.toggle} />
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Label>SHIPPING ADDRESS</Label>
                                    <p>{prodDetails.userInfo.shippingAddress}</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="no-border">
                                    <Label>APPOINTMENTS</Label>
                                    <ol>
                                        {prodDetails.profileInfo.appointmentList &&
                                            prodDetails.profileInfo.appointmentList.map((data, i) => {
                                                const status = data.status === "1" ? "PENDING" : data.status === "2" ? "UPCOMING" : "COMPLETED";
                                                const time = this.timeSlot(data.slot);
                                                const requestSlot = this.timeSlot(data.requestedSlot);
                                                const apptDate = this.formatDate(data.appointmentDate);
                                                const requestDate = this.formatDate(data.requestedDate);
                                                const requestedChanged = data.status === "1";
                                                const requestTime = requestedChanged ? `${requestDate}, ${requestSlot} REQUESTED` : "";

                                                return (
                                                    <li key={`${data.appointmentDate}_${i}`}>
                                                        {`${apptDate}, ${time} - ${status}`}
                                                        <span className="request-slot">{`${requestTime}`}</span>
                                                    </li>
                                                );
                                            })}
                                    </ol>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(ClientCardComponent);
