import { eye, eyeDisabled } from "react-icons-kit/ionicons/";

export const tHeader = {
    newsList: [
        {
            label: "POSTED DATE",
            key: "postedDate",
            format: "date",
        },
        {
            label: "NEWS",
            key: "message",
        },
        {
            label: "",
            icon: eye,
            disabledIcon: eyeDisabled,
            key: "eyes",
        },
    ],
    rewardsLists: [
        {
            label: "THUMBNAILS",
            key: "image",
            format: "img",
        },
        {
            label: "ITEM NAME",
            key: "itemName",
        },
        {
            label: "POINTS (PT)",
            key: "points",
        },
        {
            label: "STOCKLIST",
            key: "quantity",
        },
        {
            label: "",
            key: "edit",
        },
        {
            label: "",
            key: "delete",
        },
    ],
};

export const tBody = {
    newsList: [
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
    ],
};
