import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import Icon from "react-icons-kit";
import { eye } from "react-icons-kit/ionicons/";
import * as Yup from "yup";

import { api_Req } from "common/services";
import { actions } from "../../redux/actions/actions";
import { Layout, Title, Button, LoginCard } from "components";

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isPasswordShown: false,
        };
    }

    handleSubmit = (values) => {
        const { history } = this.props;
        const payload = {
            Password: values.password,
        };

        api_Req.put
            .forceResetPassword(payload)
            .then((resp) => {
                history.push("/resetPasswordCompleted", { resetCompleted: true });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    togglePassword = () => {
        const { isPasswordShown } = this.state;
        this.setState({
            isPasswordShown: !isPasswordShown,
        });
    };

    render() {
        const { isPasswordShown } = this.state;

        return (
            <Layout>
                <div className="reset-password_container">
                    <LoginCard>
                        <div className="reset-password_content">
                            <Title title="RESET PASSWORD" />
                            <p className="forgotpass_text">Enter a new password below for your account</p>
                            {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
                            <Formik
                                initialValues={{ password: "", confirmPassword: "" }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().required("Required").min(6, "Minimum 6 character password").max(20, "Try a shorter password."),
                                    confirmPassword: Yup.string()
                                        .required("Required")
                                        .min(6, "Minimum 6 character password")
                                        .max(20, "Try a shorter password.")
                                        .test("passwords-match", "Passwords must match", function (value) {
                                            return this.parent.password === value;
                                        }),
                                })}
                                onSubmit={(values) => {
                                    this.handleSubmit(values);
                                }}>
                                {(props) => {
                                    const { touched, errors, handleBlur, handleChange, handleSubmit, values } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="formStyle">
                                            <div className="icon_container">
                                                <Icon className={`eyes ${!isPasswordShown ? "disabled-eye" : null}`} icon={eye} size={25} onClick={this.togglePassword} />
                                                <input
                                                    id="password"
                                                    type={!isPasswordShown ? "password" : "text"}
                                                    name="password"
                                                    placeholder="New Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.password && touched.password ? "text-input error" : "text-input"}
                                                />
                                            </div>
                                            {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                            <div className="icon_container">
                                                <input
                                                    id="confirmPassword"
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder="Confirm Password"
                                                    value={values.confirmPassword}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.confirmPassword && touched.confirmPassword ? "text-input error" : "text-input"}
                                                />
                                            </div>
                                            {errors.confirmPassword && touched.confirmPassword && <div className="input-feedback">{errors.confirmPassword}</div>}
                                            <Button className="reset-button" type="submit" title="RESET PASSWORD" />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </LoginCard>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    setProfileToken: (data) => {
        dispatch({ type: actions.SET_USER_PROFILE_AUTH, data });
    },
    resetAllState: () => dispatch({ type: actions.INIT_USER_PROFILE }),
    fetchUserProfile: (history) => dispatch({ type: actions.FETCH_USER_PROFILE, payload: { history } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
