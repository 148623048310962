import React, { PureComponent } from "react";
import moment from "moment";

export default class Footer extends PureComponent {
    render() {
        const year = moment().format("YYYY");
        return (
            <footer className="footer_container">
                <div className="footer_text_left">
                    <p>@ {year} Hoya. All Rights Reserved.</p>
                </div>
                <div className="footer_text_right">
                    <p className="footer_text_right_p">Privacy</p>
                    <p>Terms of Use</p>
                </div>
            </footer>
        );
    }
}
