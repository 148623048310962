import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import Icon from "react-icons-kit";
import { ic_lock_outline } from "react-icons-kit/md/ic_lock_outline";
import { ic_home } from "react-icons-kit/md/ic_home";
import * as Yup from "yup";

import { api_Req } from "common/services";
import { actions } from "../../redux/actions/actions";
import { Layout, Title, Button, LoginCard } from "components";
import { forceResetPass } from "common/helper/utilities";

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
        };
    }

    componentDidMount() {
        this.props.resetAllState();
    }

    handleSubmit = (values) => {
        if (values.branchNum && values.password) {
            const payload = {
                Code: values.branchNum,
                Password: values.password,
            };
            api_Req.post
                .login(payload)
                .then((resp) => {
                    this.props.setProfileToken(resp.data);
                    this.validateToken(resp.data);
                })
                .catch((err) => {
                    this.props.alertError(err);
                });
        }
    };

    validateToken = (data) => {
        const { history } = this.props;
        const forceResetPassword = forceResetPass(data.token);
        if (forceResetPassword) {
            history.push("resetpassword", { forceResetPassword: forceResetPassword });
        } else {
            this.props.fetchUserProfile(history);
        }
    };

    render() {
        return (
            <Layout>
                <div className="login_container">
                    <LoginCard>
                        <div className="login_content">
                            <Title title="ADMIN LOGIN" />
                            {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
                            <Formik
                                initialValues={{ branchNum: "", password: "" }}
                                validationSchema={Yup.object().shape({
                                    branchNum: Yup.string().label().required("Required"),
                                    password: Yup.string().required("Required").min(6, "Minimum 6 character password").max(20, "Try a shorter password."),
                                })}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    this.handleSubmit(values);
                                }}
                            >
                                {(props) => {
                                    const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, values } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="formStyle">
                                            <div className="icon_container">
                                                <Icon icon={ic_home} size={20} />
                                                <input
                                                    id="branchNum"
                                                    type="text"
                                                    name="branchNum"
                                                    placeholder="Branch Number"
                                                    value={values.branchNum}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.branchNum && touched.branchNum ? "text-input error" : "text-input"}
                                                />
                                            </div>
                                            {errors.branchNum && touched.branchNum && <div className="input-feedback">{errors.branchNum}</div>}
                                            <div className="icon_container">
                                                <Icon icon={ic_lock_outline} size={20} />
                                                <input
                                                    id="password"
                                                    type="password"
                                                    name="password"
                                                    placeholder="Password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.password && touched.password ? "text-input error" : "text-input"}
                                                />
                                            </div>
                                            {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                            <Button className="login-button" type="submit" title="LOGIN" disabled={isSubmitting} />
                                        </form>
                                    );
                                }}
                            </Formik>
                            <p className="fp_text">
                                <Link to="/forgetpassword">Forgot my password </Link>
                            </p>
                            <p className="fp_switch">
                                <Link to="/super-admin">Switch to Super MIYO</Link>
                            </p>
                        </div>
                    </LoginCard>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    setProfileToken: (data) => {
        dispatch({ type: actions.SET_USER_PROFILE_AUTH, data });
    },
    resetAllState: () => dispatch({ type: actions.INIT_USER_PROFILE }),
    fetchUserProfile: (history) => dispatch({ type: actions.FETCH_USER_PROFILE, payload: { history } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
