import React, { PureComponent } from "react";
import { Card, CardBody, Table } from "reactstrap";
import Icon from "react-icons-kit";
import { chevronLeft, trashA } from "react-icons-kit/ionicons/";
import { ic_add_box } from "react-icons-kit/md";
import { pencil } from "react-icons-kit/typicons";

import Pagination from "react-js-pagination";
import moment from "moment";

export default class CardTableComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activePagination: 1,
        };
    }

    handlePageChange = (i) => {
        this.setState({
            activePagination: i,
        });
    };

    renderPagination = () => {
        const { paginationTotal } = this.props;
        const { activePagination } = this.state;
        if (paginationTotal) {
            return (
                <Pagination
                    activePage={activePagination}
                    itemsCountPerPage={1}
                    pageRangeDisplayed={7}
                    totalItemsCount={paginationTotal}
                    firstPageText={"Prev"}
                    lastPageText={"Next"}
                    itemClassFirst="pagination-first-child"
                    itemClassLast="pagination-last-child"
                    onChange={this.handlePageChange}
                />
            );
        }
    };

    renderTableBody = () => {
        const { tableTBodyData, disabledEdit, editAppt, onClick, onDelete, viewDetails, tableTHeadData } = this.props;

        if (tableTBodyData && !tableTBodyData.length) {
            return (
                <tr className="no-records">
                    <td>NO RECORD FOUND.</td>
                </tr>
            );
        }

        return tableTBodyData.map((data, i) => {
            const keys = Object.keys(data);
            return (
                <tr key={`${i}_tr`}>
                    {tableTHeadData.map((thead, j) => {
                        const index = keys.indexOf(thead.key);
                        if (thead.key === keys[index]) {
                            switch (thead.format) {
                                case "date":
                                    return <td key={`${thead.key}_${j}_${i}`}>{moment(data[keys[index]]).format("DD/MM/YYYY")}</td>;
                                case "dateTime":
                                    return <td key={`${thead.key}_${j}_${i}`}>{moment(data[keys[index]]).format("DD/MM/YYYY") + " " + data["appointmentTime"]}</td>;
                                case "points":
                                    return <td key={`${thead.key}_${j}_${i}`}>{`+${data[keys[index]]} points`}</td>;
                                case "img":
                                    return (
                                        <td key={`${thead.key}_${j}_${i}`}>
                                            <div className="image-container" style={{ backgroundImage: `url(${data[keys[index]]})` }} />
                                        </td>
                                    );
                                case "boolean":
                                    return (
                                        <td key={`${thead.key}_${j}_${i}`} className={thead.key}>
                                            {data[keys[index]].toString()}
                                        </td>
                                    );
                                default:
                                    return (
                                        <td key={`${thead.key}_${j}_${i}`} className={thead.key}>
                                            {data[keys[index]]}
                                        </td>
                                    );
                            }
                        } else if (!disabledEdit && thead.key === "edit") {
                            return (
                                <td className="edit" key={`edit_${i}`}>
                                    <Icon icon={pencil} onClick={() => editAppt(data)} size={20} />
                                </td>
                            );
                        } else {
                            switch (thead.key) {
                                case "view":
                                    return (
                                        <td className="view" key={`view_${i}`}>
                                            <Icon icon={ic_add_box} onClick={() => viewDetails(data)} />
                                        </td>
                                    );
                                case "eyes":
                                    const eyes = data.active ? thead.icon : thead.disabledIcon;
                                    const disabledColor = !data.active ? "disabled" : "";
                                    return (
                                        <td className={`action ${disabledColor}`} key={`action_${i}_${thead.key}`}>
                                            <Icon icon={eyes} onClick={() => onClick(data)} size={23} />
                                        </td>
                                    );
                                case "delete":
                                    return (
                                        <td key={`action_${i}_${thead.key}`}>
                                            <Icon icon={trashA} onClick={() => onDelete(data)} size={23} />
                                        </td>
                                    );
                                default:
                                    return null;
                            }
                        }
                    })}
                </tr>
            );
        });
    };

    render() {
        const { title, tableTHeadData, type, goBack, onHeaderClick } = this.props;
        const customStyle = type ? type : null;
        return (
            <div className={["card-container", customStyle].join(" ")}>
                <div className="card-header-container">
                    {title && (
                        <h1 className="card-title">
                            {title}
                            {onHeaderClick && <Icon className="add-icon" icon={ic_add_box} size={20} onClick={onHeaderClick} />}
                        </h1>
                    )}
                    {goBack && (
                        <div className="back-btn-container" onClick={goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>Back</p>
                        </div>
                    )}
                </div>
                <Card>
                    <CardBody>
                        <Table>
                            <thead>
                                <tr>
                                    {tableTHeadData &&
                                        tableTHeadData.map((tHead, i) => {
                                            const width = !tHead.label ? "30" : null;
                                            return (
                                                <th key={`${tHead.label}_${i}`} width={width}>
                                                    {tHead.label}
                                                </th>
                                            );
                                        })}
                                </tr>
                            </thead>
                            <tbody>{this.renderTableBody()}</tbody>
                        </Table>
                        {this.renderPagination()}
                    </CardBody>
                </Card>
            </div>
        );
    }
}
