import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";
import { Label, Input, Card } from "reactstrap";
import { Formik } from "formik";
import Dropzone from "react-dropzone";

import { HomeLayout, Button, Title } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { addVideoInputValidator } from "../utilities/mediaInputData";

class SuperAdminMediaAddVideo extends Component {
    onSubmit = (values) => {
        const formData = new FormData();
        formData.append("articleType", 3);
        formData.append("title", values.title);
        formData.append("thumbnail", values.thumbnail);
        formData.append("file", values.videoFile);

        super_Api_Req.post
            .addArticle(formData)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-add-video-container">
                    <div className="title-container">
                        <Title title="ADD VIDEO" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>
                    <div className="form-container">
                        <Formik
                            initialValues={{
                                title: "",
                                thumbnail: "",
                                thumbnailName: "",
                                videoFile: "",
                                videoFileName: "",
                            }}
                            validationSchema={addVideoInputValidator}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.onSubmit(values);
                            }}>
                            {(props) => {
                                const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, values } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="form-wrapper">
                                        <div className="input-container">
                                            <Label>TITLE</Label>
                                            <Input
                                                id="title"
                                                name="title"
                                                type="text"
                                                value={values.title}
                                                className={`
                          text-input
                          ${errors.title && touched.title ? "error" : ""}
                        `}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                            />
                                            {errors.title && touched.title && <div className="input-feedback">{errors.title}</div>}
                                        </div>
                                        <div className="input-container">
                                            <Label>THUMBNAIL</Label>
                                            {errors.thumbnail && touched.thumbnail && <div className="input-feedback">{errors.thumbnail}</div>}
                                            <Card className="drop-file-zone">
                                                <Dropzone
                                                    accept="image/*"
                                                    id="thumbnail"
                                                    name="thumbnail"
                                                    onDrop={(file) => {
                                                        if (file) {
                                                            if (file.length !== 0) {
                                                                setFieldValue("thumbnail", file[0]);
                                                                setFieldValue("thumbnailName", file[0].name);
                                                            }
                                                        }
                                                    }}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()} className="drop-file">
                                                                <p className="desc">{values.thumbnailName ? values.thumbnailName : `DROP IMG FILE HERE`}</p>
                                                                <p className="desc">- or -</p>
                                                                <input {...getInputProps()} accept="image/*" />
                                                                <Button type="button" title={values.thumbnail ? "REUPLOAD FILE" : "SELECT FILE"} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Card>
                                        </div>
                                        <div className="input-container">
                                            <Label>VIDEO FILE</Label>
                                            {errors.videoFile && touched.videoFile && <div className="input-feedback">{errors.videoFile}</div>}
                                            <Card className="drop-file-zone">
                                                <Dropzone
                                                    accept="video/*"
                                                    id="videoFile"
                                                    name="videoFile"
                                                    onDrop={(file) => {
                                                        if (file) {
                                                            if (file.length !== 0) {
                                                                setFieldValue("videoFile", file[0]);
                                                                setFieldValue("videoFileName", file[0].name);
                                                            }
                                                        }
                                                    }}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()} className="drop-file">
                                                                <p className="desc">{values.videoFileName ? values.videoFileName : `DROP VIDEO FILE HERE`}</p>
                                                                <p className="desc">- or -</p>
                                                                <input {...getInputProps()} accept="video/*" />
                                                                <Button type="button" title={values.videoFile ? "REUPLOAD FILE" : "SELECT FILE"} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Card>
                                        </div>
                                        <div className="button-container">
                                            <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                            <Button type="button" title="CANCEL" outline onClick={() => this.props.history.push("super-media-dashboard")} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminMediaAddVideo);
