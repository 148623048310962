import React, { PureComponent, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import Icon from "react-icons-kit";
import { calendar } from "react-icons-kit/feather/calendar";
import { user } from "react-icons-kit/icomoon";
import { mail } from "react-icons-kit/feather/mail";
import { users } from "react-icons-kit/fa/users";
import { ic_lock_open, ic_info_outline } from "react-icons-kit/md";
import jwt from "jsonwebtoken";
import { connect } from "react-redux";

import { TopNav } from "../..";
import profilePic from "../../../assets/img/profile.png";
import { api_Req, super_Api_Req } from "common/services";
import { actions } from "../../../redux/actions/actions";
import { superSideNavData } from "./utilities/sideNavData";

const roleKey = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

class HomeLayoutComponent extends PureComponent {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            profile: profilePic,
            notificationList: [],
        };
    }

    componentDidMount() {
        const { userProfile, history } = this.props;
        const auth = jwt.decode(userProfile.auth.token);
        const pathName = history.location.pathname;

        if (auth[roleKey] === "SUPER_ADMIN") {
            if (!pathName.includes("super")) {
                history.push("super-home");
            }
        } else {
            if (pathName.includes("super")) {
                history.push("adminhome");
            }
        }

        this._isMounted = true;
        this.setActiveMenu();
        this.getNotificationList();
    }

    setActiveMenu = () => {
        const {
            history: { location },
        } = this.props;
        let activePath = "";
        switch (location.pathname) {
            case "/contactus":
                activePath = "contactUs";
                break;
            case "/clientlist":
            case "/clientDetails":
                activePath = "clientManagement";
                break;
            case "/corporateinfo":
                activePath = "corporateInfo";
                break;
            case "/appointmentcalendar":
            case "/editappointment":
            case "/warranty-request":
                activePath = "appointmentcalendar";
                break;
            case "/adminhome":
                activePath = "adminHome";
                break;
            case "/super-add-news":
            case "/super-add-promo":
            case "/super-edit-promo":
                activePath = "/super-home";
                break;
            case "/super-ecp-edit":
            case "/super-ecp-add":
                activePath = "/super-ecp-dashboard";
                break;
            case "/super-prod-details":
                activePath = "/super-prod-dashboard";
                break;
            case "/super-rewards-edit":
                activePath = "/super-rewards-dashboard";
                break;
            case "/super-media-add-video":
            case "/super-media-edit-video":
            case "/super-media-add-article":
            case "/super-media-edit-article":
            case "/super-media-add-research":
            case "/super-media-edit-research":
                activePath = "/super-media-dashboard";
                break;
            default:
                activePath = location.pathname;
                break;
        }
        this.setState({
            activePath,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    getNotificationList = () => {
        const { role } = this.props;
        if (!role) {
            api_Req.get
                .notificationList()
                .then((resp) => {
                    this._isMounted &&
                        this.setState({
                            notificationList: resp.data.notificationList,
                            unseenNotification: resp.data.unseenNotification,
                        });
                })
                .catch((err) => {});
        } else {
            super_Api_Req.get
                .notification()
                .then((resp) => {
                    this._isMounted &&
                        this.setState({
                            notificationList: resp.data,
                        });
                })
                .catch((err) => {});
        }
    };

    logout = () => {
        const { history, role } = this.props;
        const path = role ? `/${role}` : "/admin";

        api_Req.post
            .logout()
            .then((resp) => {
                this.props.resetAllState();
                history.push(path);
            })
            .catch((err) => {});
    };

    navigateTo = (path) => {
        const { history } = this.props;
        if (path === "/super-admin") {
            this.logout();
        } else {
            history.push(path);
        }
    };

    superSideNav = () => {
        const { activePath } = this.state;
        return superSideNavData.map((s, i) => {
            const activated = activePath === s.link ? "activated" : "";
            return (
                <div key={i} className={`side-bar-element ${activated}`} onClick={() => this.navigateTo(s.link)}>
                    <Icon className="side-bar-icon" icon={s.icon} size={20} />
                    {s.label}
                </div>
            );
        });
    };

    render() {
        const { activePath, notificationList, unseenNotification } = this.state;
        const { userProfile, role } = this.props;
        return (
            <div className="layoutContainer">
                <div>
                    <TopNav logout={this.logout} name={userProfile.profile.corporateName} notificationList={notificationList} unseenNotification={unseenNotification} role={this.props.role} />
                </div>
                <div className="verticalFLexContainer">
                    {/* sidebar */}
                    <div className="vflex1-sidebar">
                        <div className="profilePicAndNameCointainer">
                            <p className="profile-name">{userProfile.profile.corporateName || "ADMIN"}</p>
                        </div>
                        {!role ? (
                            <Fragment>
                                <Link to="/adminhome">
                                    <div className={`side-bar-element ${activePath === "adminHome" ? "activated" : ""}`}>
                                        <Icon className="side-bar-icon" icon={user} />
                                        Home
                                    </div>
                                </Link>
                                <Link to="/corporateinfo">
                                    <div className={`side-bar-element ${activePath === "corporateInfo" ? "activated" : ""}`}>
                                        <Icon className="side-bar-icon" icon={ic_info_outline} />
                                        Corporate Info
                                    </div>
                                </Link>
                                <Link to="/appointmentcalendar">
                                    <div className={`side-bar-element ${activePath === "appointmentcalendar" ? "activated" : ""}`}>
                                        <Icon className="side-bar-icon" icon={calendar} />
                                        Appoinments
                                    </div>
                                </Link>
                                <Link to="/clientlist">
                                    <div className={`side-bar-element ${activePath === "clientManagement" ? "activated" : ""}`}>
                                        <Icon className="side-bar-icon" icon={users} />
                                        Client Management
                                    </div>
                                </Link>
                                <Link to="/contactus">
                                    <div className={`side-bar-element ${activePath === "contactUs" && "activated"}`}>
                                        <Icon className="side-bar-icon" icon={mail} />
                                        Contact Us
                                    </div>
                                </Link>
                                <div className="side-bar-element" onClick={this.logout}>
                                    <Icon
                                        style={{
                                            position: "relative",
                                            bottom: "2px",
                                            paddingRight: "10px",
                                        }}
                                        icon={ic_lock_open}
                                    />
                                    Log Out
                                </div>
                            </Fragment>
                        ) : (
                            this.superSideNav()
                        )}
                    </div>
                    <div className="vflex2">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    resetAllState: () => dispatch({ type: actions.INIT_USER_PROFILE }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(HomeLayoutComponent);
