import React, { Component } from "react";
import { connect } from "react-redux";
import { Label, Input } from "reactstrap";
import { Formik } from "formik";
import moment from "moment";

import { HomeLayout, Button } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { ecpEditInputData, inputValidator } from "../utilities/ecpInputData";

class SuperAdminECPEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ecpDetails: {},
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (!history.location.state) return history.push("super-ecp-dashboard");
        this.ecpDetails(history.location.state.data);
    }

    ecpDetails = (data) => {
        super_Api_Req.get
            .ecpDetails(data.id)
            .then((resp) => {
                this.setState({
                    ecpDetails: resp.data,
                });
                console.log(resp.data);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    genRandomPassword = () => {
        return Math.random().toString(36).slice(2);
    };

    handleSubmit = (v) => {
        const { ecpDetails } = this.state;
        const { history } = this.props;
        const payload = {
            StoreId: ecpDetails.storeId,
            Address: v.address,
            PersonInCharge: v.pic,
            MobileNumber: v.contact,
            Email: v.email,
            Remark: v.remark,
        };
        if (v.password) {
            payload.Password = v.password;
        }

        super_Api_Req.put
            .ecpDetails(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { ecpDetails } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-ecp-edit-container">
                    <div className="ecp-header-container">
                        <h1 className="ecp-title">EDIT ECP</h1>
                    </div>
                    {Object.keys(ecpDetails).length ? (
                        <div className="form-container">
                            <Formik
                                initialValues={{
                                    date: moment(ecpDetails.date).format("DD/MM/YYYY"),
                                    ecpCode: ecpDetails.ecpCode,
                                    address: ecpDetails.address,
                                    pic: ecpDetails.personInCharge,
                                    contact: ecpDetails.mobileNumber,
                                    email: ecpDetails.email,
                                    remark: ecpDetails.remark,
                                    password: "",
                                }}
                                validationSchema={inputValidator}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    this.handleSubmit(values);
                                }}>
                                {(props) => {
                                    const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, values } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="form-wrapper">
                                            <div className="input-col">
                                                {ecpEditInputData.map((o, i) => {
                                                    const error = errors[o.name] && touched[o.name] ? "error" : "";
                                                    const inputName = `input-${o.name}`;

                                                    if (o.type === "textarea") {
                                                        return (
                                                            <div className="input-container">
                                                                <Label>{o.label}</Label>
                                                                <textarea className={`textarea-input ${inputName} ${error}`} id={o.name} value={values[o.name]} onChange={handleChange} onBlur={handleBlur} rows="4" />
                                                                {errors[o.name] && touched[o.name] && <div className="input-feedback">{errors[o.name]}</div>}
                                                            </div>
                                                        );
                                                    } else {
                                                        return (
                                                            <div className="input-container">
                                                                <Label>{o.label}</Label>
                                                                <Input
                                                                    id={o.name}
                                                                    type="text"
                                                                    value={values[o.name]}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    autoComplete="off"
                                                                    disabled={o.disabled}
                                                                    className={`text-input ${inputName} ${error}`}
                                                                />
                                                                {errors[o.name] && touched[o.name] && <div className="input-feedback">{errors[o.name]}</div>}
                                                            </div>
                                                        );
                                                    }
                                                })}
                                                <div className="button-container">
                                                    <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                                    <Button title="CANCEL" type="button" outline onClick={this.props.history.goBack} />
                                                </div>
                                            </div>
                                            <div className="input-col">
                                                <div className="input-container">
                                                    <Label>UPDATE / CHANGE PASSWORD</Label>
                                                    <Input id="password" type="text" name="password" value={values.password} autoComplete="off" className={`text-input ${errors.password && touched.password ? "error" : ""}`} disabled />
                                                    {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                                </div>
                                                <Button type="button" title="GENERATE PASSWORD" onClick={() => setFieldValue("password", this.genRandomPassword())} />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    ) : null}
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminECPEdit);
