import React, { Fragment, PureComponent } from "react";
import Icon from "react-icons-kit";
import { Badge } from "reactstrap";
import { bell } from "react-icons-kit/typicons/bell";
import { Link, withRouter } from "react-router-dom";
import { users } from "react-icons-kit/fa/users";
import { connect } from "react-redux";
import { compose } from "redux";
import { actions } from "../../../redux/actions/actions";

import { mail } from "react-icons-kit/feather/mail";
import { ic_person_outline, ic_info_outline } from "react-icons-kit/md/";
import { iosUnlockedOutline, chevronDown } from "react-icons-kit/ionicons";
import { calendar } from "react-icons-kit/feather/calendar";
import { api_Req } from "common/services";

import { superTopNavDropdownData } from "./utilities/topNavDropdownData";
import hoyaLogo from "../../../assets/img/headLogo.png";

class TopNavComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            dropdownBell: false,
        };
    }

    dropdownBellToggle = () => {
        this.setState({
            dropdownBell: !this.state.dropdownBell,
        });
    };

    toggleDropdown = () => {
        this.setState({
            dropdown: !this.state.dropdown,
        });
    };

    seenNotificationItem = (data) => {
        const { history } = this.props;
        api_Req.put
            .seenNotification(data.id)
            .then((resp) => {
                history.push("clientDetails", { data });
                this.dropdownBellToggle();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    navigateTo = (path) => {
        const { history } = this.props;
        if (path === "/super-admin") {
            this.props.logout();
        } else {
            history.push(path);
        }
    };

    superTopNavDropdown = () => {
        const { activePath } = this.state;
        return superTopNavDropdownData.map((s, i) => {
            const activated = activePath === s.link ? "activated" : "";
            return (
                <div className={`dropdownElements ${activated}`} onClick={() => this.navigateTo(s.link)}>
                    <Icon className="side-bar-icon" icon={s.icon} size={20} />
                    {s.label}
                </div>
            );
        });
    };

    render() {
        const { notificationList, unseenNotification, role } = this.props;
        return (
            <Fragment>
                <nav className="topbar">
                    <div className="navLeftsideElements">
                        <div className="hoyaLogo">
                            <img src={hoyaLogo} alt="" />
                        </div>
                    </div>
                    <div className="navRightSideElements">
                        <div className="RightSideElementstyle topbar__bell">
                            <Icon style={{ color: "#2F368F" }} size={30} icon={bell} onClick={this.dropdownBellToggle} />
                            {unseenNotification !== 0 && (
                                <Badge style={{ position: "absolute", top: -5, right: -5, borderRadius: 100, fontSize: 12 }} color="danger">
                                    {unseenNotification}
                                </Badge>
                            )}
                            {this.state.dropdownBell && (
                                <div>
                                    <div className="dropdownBellOptionContainer">
                                        {notificationList &&
                                            notificationList.map((data, i) => {
                                                const seen = data.hasSeen ? "seen" : null;
                                                return (
                                                    <div className={`notification-list ${seen}`} key={`${data.createdAtUtc}_${i}`} onClick={() => this.seenNotificationItem(data)}>
                                                        {data.message}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="profile-name">Welcome, {this.props.name || "Admin"}</div>
                        <div className="dropdownContainer" onClick={this.toggleDropdown}>
                            <div className="dropdownChevronLogo">
                                <Icon icon={chevronDown} size={22} />
                            </div>
                            {this.state.dropdown && (
                                <div className="dropdownOptionAndArrowContainer">
                                    {!role ? (
                                        <div className="dropdownOptionContainer">
                                            <Link to="/profile">
                                                <div className="dropdownElements">
                                                    <Icon icon={ic_person_outline} size={20} />
                                                    Home
                                                </div>
                                            </Link>
                                            <Link to="/corporateinfo">
                                                <div className="dropdownElements">
                                                    <Icon icon={ic_info_outline} size={20} />
                                                    Corporate Info
                                                </div>
                                            </Link>
                                            <Link to="/appointmentcalendar">
                                                <div className="dropdownElements">
                                                    <Icon icon={calendar} size={20} />
                                                    Appointments
                                                </div>
                                            </Link>
                                            <Link to="/clientlist">
                                                <div className="dropdownElements">
                                                    <Icon icon={users} size={20} />
                                                    Client Management
                                                </div>
                                            </Link>
                                            <Link to="/contactUs">
                                                <div className="dropdownElements">
                                                    <Icon icon={mail} size={20} />
                                                    Contact Us
                                                </div>
                                            </Link>
                                            <div className="dropdownElements logout-btn" onClick={this.props.logout}>
                                                <Icon icon={iosUnlockedOutline} size={20} />
                                                Log Out
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="dropdownOptionContainer">{this.superTopNavDropdown()}</div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </nav>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    resetAllState: () => dispatch({ type: actions.INIT_USER_PROFILE }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TopNavComponent);
