import React, { Component } from "react";
import { connect } from "react-redux";
import { HomeLayout, Button, CardTable } from "components";
import { super_Api_Req } from "common/services";
import ShippingDetailsCard from "components/super-admin/shippingDetailsCard/shippingDetailsCard";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal, Col } from "reactstrap";

import { actions } from "../../../../redux/actions/actions";
import { tHeader, shippingDetails, pointsData } from "../utilities/tableData";

class SuperAdminRedemptionsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redemptionsDetails: {},
            productList: {},
            shippingDetailsdata: {},
            activeEditField: {},
            FormPopup: false,
            points: {},
            reducedPointsTota: 0,
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state) {
            this.requestRedemptionDetails(history.location.state.data);
            this.sumProduct();
        } else {
            history.push("super-redemptions-dashboard");
        }
    }

    closePopUp = () => {
        this.setState({ FormPopup: false });
    };

    displayProfileUsingActiveStates = () => {
        shippingDetails[0].desc = this.state.redemptionsDetails.customerName;
        shippingDetails[1].desc = this.state.redemptionsDetails.mobileNumber;
        shippingDetails[2].desc = this.state.redemptionsDetails.shippingAddress;

        this.setState({
            shippingDetailsdata: shippingDetails,
        });
    };

    setPoints = () => {
        pointsData[0].points = this.state.redemptionsDetails.pointsBalance;
        this.setState({
            points: pointsData,
        });
    };

    handleSubmit = (v) => {
        const { history } = this.props;

        const payload = {
            TransactionId: history.location.state.data.id,
            Name: this.state.activeEditField === "FULL NAME" ? v.field : this.state.redemptionsDetails.customerName,
            MobileNumber: this.state.activeEditField === "CONTACT NUMBER" ? v.field : this.state.redemptionsDetails.mobileNumber,
            ShippingAddress: this.state.activeEditField === "SHIPPING ADDRESS" ? v.field : this.state.redemptionsDetails.shippingAddress,
            Points: this.state.activeEditField === "Available Points" ? v.field : this.state.redemptionsDetails.pointsBalance,
        };

        super_Api_Req.put
            .redeemUpdate(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    requestRedemptionDetails = (data) => {
        const { history } = this.props;
        super_Api_Req.get
            .redemptionsDetails(data.id)
            .then((resp) => {
                this.setState(
                    {
                        redemptionsDetails: resp.data,
                        productList: resp.data.productList,
                    },
                    () => {
                        this.displayProfileUsingActiveStates();
                        this.setPoints();
                    }
                );
            })
            .catch((err) => {
                this.props.alertError(err);
                history.push("super-redemptions-dashboard");
            });
    };

    onEdit = (data) => {
        this.setState({ activeEditField: data }, () => {
            this.setState({ FormPopup: true });
        });
    };

    sumProduct = () => {
        const { productList } = this.state;
        if (!productList.length) return;
        const total = productList.reduce((a, b) => a + b.quantity * b.points, 0);

        return total;
    };

    render() {
        const { productList, shippingDetailsdata, points } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-redemptions-dashboard-container">
                    {this.state.FormPopup === true && (
                        <Modal isOpen={this.state.FormPopup} className={"rredemption_modalContainer"} contentClassName={"rredemption_modalContent"}>
                            <h1 className={"rredemption_popuptitle"}>UPDATES SHIPPING DETAILS</h1>
                            <Formik
                                initialValues={{
                                    field:
                                        this.state.activeEditField === "FULL NAME"
                                            ? this.state.redemptionsDetails.customerName
                                            : this.state.activeEditField === "CONTACT NUMBER"
                                            ? this.state.redemptionsDetails.mobileNumber
                                            : this.state.activeEditField === "SHIPPING ADDRESS"
                                            ? this.state.redemptionsDetails.shippingAddress
                                            : this.state.redemptionsDetails.pointsBalance,
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    console.log("sent");
                                    setSubmitting(false);
                                    this.handleSubmit(values);
                                }}
                                validationSchema={Yup.object().shape({
                                    field: Yup.string().required("Required"),
                                })}>
                                {(props) => {
                                    const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="rredemption_formStyle">
                                            <label className="rredemption-form-label">{this.state.activeEditField}</label>
                                            <input
                                                id="field"
                                                placeholder={this.state.activefieldTochange === "FULL NAME" ? this.state.activeName : this.state.activefieldTochange === "CONTACT NUMBER" ? this.state.activeMobile : this.state.activeAddress}
                                                value={values.field}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                className={errors.field && touched.field ? "rredemption_text-input error" : "rredemption_text-input"}
                                            />
                                            {errors.field && touched.field && <div className="input-feedback">{errors.field}</div>}

                                            <div className="rredemption_ButtonContainer">
                                                <Button className="rredemption_buttonStyle" onClick={() => this.closePopUp()} outline title="Cancel" />
                                                <Button title="Confirm" disabled={isSubmitting} type="submit" className="rredemption_buttonStyle" />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </Modal>
                    )}

                    <Col>
                        {this.state.productList.length > 0 && (
                            <div>
                                <CardTable title="Redemption Details" tableTHeadData={tHeader.redemptionsDetails} tableTBodyData={productList} goBack={this.props.history.goBack} />
                                <h4 className="redemption-details-points">
                                    TOTAL REDEEMED POINTS
                                    <h5>{this.sumProduct() + `pts`}</h5>
                                </h4>
                            </div>
                        )}
                    </Col>
                    {console.log(this.state.shippingDetailsdata)}
                    <Col className="col-flex">
                        {this.state.shippingDetailsdata.length > 0 && <ShippingDetailsCard title="SHIPPING DETAILS" shippingDetails={shippingDetailsdata} onEdit={this.onEdit} />}

                        {this.state.shippingDetailsdata.length > 0 && <ShippingDetailsCard title="POINTS BALANCE" shippingDetails={points} onEdit={this.onEdit} />}
                    </Col>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminRedemptionsDetails);
