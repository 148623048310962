export const actions = {
    /* LOGIN */
    INIT_USER_PROFILE: "INIT_USER_PROFILE",
    SET_USER_PROFILE_AUTH: "SET_USER_PROFILE_AUTH",

    /* PROFILE */
    FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
    SET_USER_PROFILE: "SET_USER_PROFILE",

    /* ALERT */
    SUCCESS_ALERT: "SUCCESS_ALERT",
    ERROR_ALERT: "ERROR_ALERT",
    CLEAR_ALERT: "CLEAR_ALERT",

    /* SUPER ADMIN */
    INIT_SUPER_SUMMARY: "INIT_SUPER_SUMMARY",
    FETCH_SUPER_SUMMARY: "FETCH_SUPER_SUMMARY",
    SET_SUPER_SUMMARY: "SET_SUPER_SUMMARY",
};
