export const performanceAssesmentData = [
    {
        question: "Vision Clarity at distance",
    },
    {
        question: "Vision Clarity at intermediate distance(e.g. computer, watching TV)",
    },
    {
        question: "Vision Clarity for near tasks(e.g. reading using smartphone)",
    },
    {
        question: "Vision stability",
    },
    {
        question: "Vision comfort",
    },
    {
        question: "Vision during outdoor activities",
    },
    {
        question: "Easiness of lens adaption",
    },
    {
        question: "Overall performance",
    },
];

export const performanceSelection = [
    {
        label: "POOR",
        value: 1,
    },
    {
        label: "ACCEPTABLE",
        value: 2,
    },
    {
        label: "FAIR",
        value: 3,
    },
    {
        label: "GOOD",
        value: 4,
    },
    {
        label: "EXELLENT",
        value: 5,
    },
];
