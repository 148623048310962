import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";
import { Label, Input, Card } from "reactstrap";
import { Formik } from "formik";
import Dropzone from "react-dropzone";

import { HomeLayout, Button, Title } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { addResearchInputValidator } from "../utilities/mediaInputData";

class SuperAdminMediaAddResearch extends Component {
    onSubmit = (values) => {
        const formData = new FormData();
        formData.append("articleType", 4);
        formData.append("file", values.pdfFile);
        formData.append("title", values.title);

        super_Api_Req.post
            .addArticle(formData)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-add-research-container">
                    <div className="title-container">
                        <Title title="ADD RESEARCH PAPER" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>
                    <div className="form-container">
                        <Formik
                            initialValues={{
                                title: "",
                                pdfFile: "",
                                pdfFileName: "",
                            }}
                            validationSchema={addResearchInputValidator}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.onSubmit(values);
                            }}
                        >
                            {(props) => {
                                const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, values } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="form-wrapper">
                                        <div className="input-container">
                                            <Label>TITLE</Label>
                                            <Input
                                                id="title"
                                                name="title"
                                                type="text"
                                                value={values.title}
                                                className={`
                          text-input
                          ${errors.title && touched.title ? "error" : ""}
                        `}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                autoComplete="off"
                                            />
                                            {errors.title && touched.title && <div className="input-feedback">{errors.title}</div>}
                                        </div>
                                        <div className="input-container">
                                            <Label>PDF FILE</Label>
                                            {errors.pdfFile && touched.pdfFile && <div className="input-feedback">{errors.pdfFile}</div>}
                                            <Card className={["drop-file-zone", values.url ? "drop-file-zone--disabled" : ""].join(" ")}>
                                                <Dropzone
                                                    accept="application/pdf"
                                                    id="pdfFile"
                                                    name="pdfFile"
                                                    onDrop={(file) => {
                                                        if (file) {
                                                            if (file.length !== 0) {
                                                                setFieldValue("pdfFile", file[0]);
                                                                setFieldValue("pdfFileName", file[0].name);
                                                            }
                                                        }
                                                    }}
                                                    disabled={values.url ? true : false}
                                                >
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()} className="drop-file">
                                                                <p className="desc">{values.pdfFileName ? values.pdfFileName : `DROP PDF FILE HERE`}</p>
                                                                <p className="desc">- or -</p>
                                                                <input {...getInputProps()} accept="application/pdf" disabled={values.url ? true : false} />
                                                                <Button type="button" title={values.pdfFile ? "REUPLOAD FILE" : "SELECT FILE"} disabled={values.url ? true : false} />
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                            </Card>
                                        </div>
                                        <div className="button-container">
                                            <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                            <Button type="button" title="CANCEL" outline onClick={() => this.props.history.push("super-media-dashboard")} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminMediaAddResearch);
