import React, { Component } from "react";
import { connect } from "react-redux";

import { HomeLayout, Button } from "components";
import fb from "assets/img/facebook.png";
import insta from "assets/img/instagram.png";
import { api_Req } from "common/services";
import { actions } from "../../redux/actions/actions";

class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg: "",
        };
    }

    handleChange = (e) => {
        const { value } = e.currentTarget;
        this.setState({
            msg: value,
        });
    };

    handleSubmit = () => {
        const { msg } = this.state;
        if (msg) {
            const payload = {
                Message: msg,
            };

            api_Req.post
                .contactUs(payload)
                .then((resp) => {
                    this.setState({ msg: "" });
                    this.props.alertSuccess(resp.data.message);
                })
                .catch((err) => {
                    this.props.alertError(err);
                });
        }
    };

    render() {
        const { msg } = this.state;
        console.log(this.props.users);

        return (
            <HomeLayout>
                <div className="container-contact-us">
                    <div className="contact-us-wrapper">
                        <div className="git_container">
                            <p className="title">GET IN TOUCH</p>
                            <div>
                                <p className="description">Please fill out the form below and we will be in touch with lightning speed.</p>
                            </div>
                            <textarea className="msg_box" placeholder="Message" onChange={this.handleChange} value={this.state.Messages} cols="50" rows="4" disabled={this.state.loading} />
                            <div className="button-container">
                                <Button type="submit" title={this.state.loading ? "SUBMITTING" : "SUBMIT"} onClick={this.handleSubmit} disabled={this.state.loading} />
                            </div>
                        </div>
                        <div className="cwu_container">
                            <p className="title">CONNECT WITH US:</p>
                            <div className="cwu_text">
                                <p className="description">
                                    <b>HOYA Lens Manufacturing Malaysia Sdn. Bhd.</b>
                                    <br /> No. 6, Jalan 7/32A, Off 6 1/2 Miles, Jalan Kepong, 52000, Kuala Lumpur, WP Kuala Lumpur.
                                </p>
                            </div>
                            <div className="cwu_text">
                                <p className="description">
                                    For support or any question: <br />
                                    Email us at
                                    <a href="mailto: support@hoya.com.my"> support@hoya.com.my</a>
                                    <br />
                                    Call us at 03-6256 0081
                                </p>
                            </div>
                            <div className="sm_container">
                                <a href="https://www.facebook.com/hoyamalaysia/" target="_blank" rel="noreferrer">
                                    <img src={fb} alt="Facebook" />
                                </a>

                                <a href="https://www.instagram.com/hoyamalaysia/" target="_blank" rel="noreferrer">
                                    <img src={insta} alt="Instagram" />
                                </a>
                            </div>

                            <div className="contact-us-location">
                                <p className="title">VISIT YOUR OPTICAL CENTRE:</p>
                                <div className="cwu_container">
                                    <div className="cwu_text">
                                        <p className="description">
                                            <b>{this.props.users?.profile?.corporateName}</b>
                                            <br />
                                            {this.props.users?.profile?.address}
                                        </p>
                                    </div>
                                    <ul>
                                        {this.props.users?.profile?.businessHour.map((day, dayIndex) => {
                                            const labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Staturday", "Sunday"];
                                            const closedClassName = day.toLowerCase() === "closed" ? "closed" : "";

                                            return (
                                                <li className="bold" key={dayIndex}>
                                                    {labels[dayIndex]} <span className={`bold ${closedClassName}`}>{day}</span>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className="cwu_text">
                                        <p className="description">
                                            For support or any question: <br />
                                            Email us at
                                            <a href={`mailto: ${this.props.users?.profile?.email}`}> {this.props.users?.profile?.email}</a>
                                            <br />
                                            Call us at {this.props.users?.profile?.mobileNumber}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        users: state.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
