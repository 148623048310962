import jwt from "jsonwebtoken";

const authExist = (token) => {
    const auth = jwt.decode(token);
    return auth ? true : false;
};

const forceResetPass = (token) => {
    const auth = jwt.decode(token);
    return auth.ResetPassword === "True" ? true : false;
};

const toBase64 = (file) => {
    if (typeof file !== "object") return;
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
            if (encoded.length % 4 > 0) {
                encoded += "=".repeat(4 - (encoded.length % 4));
            }
            resolve(encoded);
        };
        reader.onerror = (error) => reject(error);
    });
};

export { authExist, forceResetPass, toBase64 };
