import React, { Component } from "react";
import Icon from "react-icons-kit";
import { FormGroup, Label, Input, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { search } from "react-icons-kit/icomoon/search";
import { connect } from "react-redux";
import moment from "moment";

import { HomeLayout, Title, Button } from "components";
import { performanceAssesmentData, performanceSelection } from "../ultilities/questionData";
import { api_Req, timeSlotData } from "common/services";
import { actions } from "../../../../redux/actions/actions";

class AppointmentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apptHistoryDetails: {
                appointmentList: [],
            },
            apptSelectedData: {
                orderNumber: "",
            },
            dropDownTime: false,
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        if (!location.state) return history.push("createappointment");
        this.setState({
            apptSelectedData: location.state.apptData,
        });
        this.requestApptDetails(location.state.apptData);
    }

    requestApptDetails = (apptData) => {
        api_Req.get
            .apptDetails(apptData.orderNumber, apptData.appointmentId)
            .then((resp) => {
                const selectedAppt = resp.data.appointmentList.filter((i) => {
                    return i.appointmentId === apptData.appointmentId;
                });
                if (selectedAppt.length > 0) {
                    const selectedDate = `${moment(selectedAppt[0].appointmentDate).format("DD/MM/YYYY")},
          ${timeSlotData[selectedAppt[0].slot - 1]["label"]}`;
                    this.setState({
                        apptHistoryDetails: resp.data,
                        dateLabel: selectedDate,
                    });
                } else {
                    this.setState({
                        apptHistoryDetails: resp.data,
                        dateLabel: "SELECT",
                    });
                }
                console.log(resp.data);
            })
            .catch((err) => {
                //err
                this.props.alertError(err);
            });
    };

    handleChange = (e) => {
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;
        this.setState({
            [name]: value,
        });
        console.log(name, value);
    };

    handleApptChange = (e) => {
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;
        const selected = e.currentTarget.selected;
        console.log(value, name, selected);
        const { apptSelectedData } = this.state;
        const payload = Object.assign({}, apptSelectedData);
        payload.appointmentId = value;
        this.setState({
            [name]: value,
            dateLabel: selected,
        });
        this.requestApptDetails(payload);
    };

    toggleDropdown = () => {
        this.setState((prevState) => ({
            dropDownTime: !prevState.dropDownTime,
        }));
    };

    render() {
        const { apptHistoryDetails, apptSelectedData, dropDownTime, dateLabel } = this.state;
        return (
            <HomeLayout>
                <div className="appthistory_container">
                    <Title title="APPOINTMENT HISTORY" />
                    <div className="appthistory_search">
                        <FormGroup>
                            <Label>Order Number</Label>
                            <div className="ordernum_container">
                                <Icon icon={search} className="icon_search" />
                                <Input className="ordernum_input" type="text" name="ordernum" placeholder="SEARCH" disabled maxLength={20} readOnly value={`${apptSelectedData.orderNumber}  ${apptHistoryDetails.profileName}` || ""} />
                            </div>
                            <div className="datetime_container">
                                <Label>Select Date and Time</Label>
                                <Dropdown isOpen={dropDownTime} toggle={this.toggleDropdown}>
                                    <DropdownToggle caret className="time-toggle-btn">
                                        {dateLabel}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {apptHistoryDetails &&
                                            apptHistoryDetails.appointmentList &&
                                            apptHistoryDetails.appointmentList.map((o, i) => {
                                                const timeSlot = timeSlotData[o.slot - 1]["label"] || "";
                                                const label = `
                          ${moment(o.appointmentDate).format("DD/MM/YYYY")}, ${timeSlot}`;
                                                return (
                                                    <DropdownItem name="date" selected={label} key={o.appointmentId} value={o.appointmentId} onClick={this.handleApptChange}>
                                                        {label}
                                                    </DropdownItem>
                                                );
                                            })}
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                            <div className="apptrecords_container">
                                <Title title="APPOINTMENT RECORDS" />
                                <div className="apptrecords_content">
                                    <Row className="apptrecords_row">
                                        <Col xs="1">RL TYPE</Col>
                                        <Col xs="2">
                                            <p>SPH*</p>
                                        </Col>
                                        <Col xs="2">
                                            <p>CYL*</p>
                                        </Col>
                                        <Col xs="2">AXIS*</Col>
                                    </Row>
                                    <Row className="apptrecords_row">
                                        <Col xs="1">RIGHT TYPE</Col>
                                        <Col xs="2">
                                            <Input name="rightSPH" disabled value={this.state.rightSPH || apptHistoryDetails.rightSPH || "-"} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input name="rightCYL" disabled value={this.state.rightCYL || apptHistoryDetails.rightCYL || "-"} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input name="rightCYL" disabled value={this.state.rightCYL || apptHistoryDetails.rightCYL || "-"} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                    <Row className="apptrecords_row">
                                        <Col xs="1">LEFT TYPE</Col>
                                        <Col xs="2">
                                            <Input name="leftSPH" disabled value={this.state.leftSPH || apptHistoryDetails.leftSPH || "-"} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input name="leftCYL" disabled value={this.state.leftCYL || apptHistoryDetails.leftCYL || "-"} onChange={this.handleChange} />
                                        </Col>
                                        <Col xs="2">
                                            <Input name="leftCYL" disabled value={this.state.leftCYL || apptHistoryDetails.leftCYL || "-"} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                    <Row className="apptrecords_textarea">
                                        <Col xs="1">REMARKS</Col>
                                        <Col xs="2" md={{ size: 6 }}>
                                            <Input disabled name="remark" type="textarea" placeholder="" value={this.state.remark || apptHistoryDetails.remark || "-"} onChange={this.handleChange} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                    <div className="performance-assesment">
                        <Title title="PERFORMANCE ASSESSMENT" />
                        <ul className="performance-lists">
                            {apptHistoryDetails.appointmentList.length > 0 &&
                                performanceAssesmentData.map((q, i) => {
                                    const key = apptHistoryDetails.assessmentList && apptHistoryDetails.assessmentList[i];
                                    const label = key ? performanceSelection[key - 1].label : "SELECT";
                                    return (
                                        <li className="performance-item" key={`${i}_question`}>
                                            <p>{`${i + 1}. ${q.question}`}</p>
                                            <Dropdown isOpen={false}>
                                                <DropdownToggle caret className="toggle-btn">
                                                    {label}
                                                </DropdownToggle>
                                            </Dropdown>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>

                    <div className="button_container">
                        <div className="button-group">
                            <Button title="WARRANTY REQUEST" onClick={() => this.props.history.push("/warranty-request", { expired: true })} />
                        </div>
                        <div className="button-group">
                            <Button title="BACK" onClick={this.props.history.goBack} />
                        </div>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(null, mapDispatchToProps)(AppointmentHistory);
