import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, PopUp } from "components";
import { actions } from "../../../../redux/actions/actions";

class MediaDeletePopup extends Component {
    render() {
        const { removeModalStatus, modalToggle, deleteArticle } = this.props;
        return (
            <PopUp visible={removeModalStatus} toggle={modalToggle} className="remove-modal-pop-up">
                <div className="popup-container">
                    <div className="popup-title-container">
                        <div className="popup-title">confirm deleteing media?</div>
                    </div>
                    <div className="popup-button-container">
                        <Button type="button" title="DELETE" onClick={deleteArticle} />
                        <Button type="button" title="CANCEL" outline onClick={modalToggle} />
                    </div>
                </div>
            </PopUp>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(MediaDeletePopup);
