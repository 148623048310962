import React, { Component } from "react";
import { connect } from "react-redux";

import { HomeLayout, CardTable } from "components";
import { tHeader } from "../utilities/tableData";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";

class SuperAdminRewardsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rewardsLists: [],
        };
    }

    componentDidMount() {
        this.requestRewardsList();
    }

    requestRewardsList = () => {
        super_Api_Req.get
            .rewardsLists()
            .then((resp) => {
                this.setState({
                    rewardsLists: resp.data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    navigateTo = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    onEdit = (data) => {
        const { history } = this.props;
        history.push("super-rewards-edit", { data });
    };

    onDeleteRewards = (data) => {
        const payload = {
            RedemptionId: data.id,
        };
        super_Api_Req.put
            .deleteRewards(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.requestRewardsList();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { rewardsLists } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-rewards-dash-container">
                    <CardTable title="REWARDS LISTS" tableTHeadData={tHeader.rewardsLists} tableTBodyData={rewardsLists} editAppt={this.onEdit} onDelete={this.onDeleteRewards} onHeaderClick={() => this.navigateTo("super-rewards-add")} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminRewardsDashboard);
