import React, { PureComponent } from "react";
import { Row, Col } from "reactstrap";
import Icon from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md";
import { pencil } from "react-icons-kit/typicons";
import { trashA } from "react-icons-kit/ionicons/";

export default class PromoCardComponent extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title, onHeaderClick, promoData, onEdit, onDelete } = this.props;
        return (
            <div className="card-container promo-container">
                <div className="promo-header-container">
                    <h1 className="promo-title">
                        {title}
                        {onHeaderClick && <Icon className="add-icon" icon={ic_add_box} size={20} onClick={onHeaderClick} />}
                    </h1>
                </div>
                <Row>
                    {promoData.map((o, i) => {
                        const filename = o.image.replace(/^.*[\\/]/, "").split(".");
                        return (
                            <Col>
                                <div
                                    className="promo-img"
                                    style={{
                                        backgroundImage: `url(${o.image})`,
                                    }}
                                />
                                <div className="promo-content">
                                    <p className="promo-name">{filename[0]}</p>
                                    <div className="promo-controller">
                                        <Icon icon={pencil} size={20} onClick={() => onEdit(o)} />
                                        <Icon icon={trashA} size={20} onClick={() => onDelete(o)} />
                                    </div>
                                </div>
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    }
}
