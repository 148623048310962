import * as Yup from "yup";

export const ecpEditInputData = [
    {
        label: "DATE CREATED",
        name: "date",
        disabled: true,
    },
    {
        label: "ECP CODE",
        name: "ecpCode",
        disabled: true,
    },
    {
        label: "ECP ADDRESS*",
        name: "address",
        type: "textarea",
    },
    {
        label: "PERSON IN CHARGE*",
        name: "pic",
    },
    {
        label: "CONTACT NUMBER*",
        name: "contact",
    },
    {
        label: "EMAIL",
        name: "email",
    },
    {
        label: "REMARK",
        name: "remark",
        type: "textarea",
    },
];

export const ecpAddInputData = [
    {
        label: "ECP CODE",
        name: "ecpCode",
        type: "dropdown",
    },
    {
        label: "ECP ADDRESS*",
        name: "address",
        type: "textarea",
    },
    {
        label: "PERSON IN CHARGE*",
        name: "pic",
    },
    {
        label: "CONTACT NUMBER*",
        name: "contact",
    },
    {
        label: "EMAIL",
        name: "email",
    },
    {
        label: "REMARK",
        name: "remark",
        type: "textarea",
    },
];

const phoneRegExp = /^(01)[0-46-9]-*[0-9]{7,8}$/;
export const inputValidator = Yup.object().shape({
    date: Yup.string().required("This Field is required."),
    // password: Yup.string().required("This Field is required."),
    email: Yup.string().email("Please insert a valid email.").required("This Field is required."),
    ecpCode: Yup.string().required("This Field is required."),
    address: Yup.string().required("This Field is required."),
    pic: Yup.string().required("This Field is required."),
    contact: Yup.string().required("This Field is required.").matches(phoneRegExp, "Phone number is not valid"),
    remark: Yup.string().required("This Field is required."),
});

export const addInputValidator = Yup.object().shape({
    email: Yup.string().email("Please insert a valid email.").required("This Field is required."),
    password: Yup.string().required("This Field is required."),
    ecpCode: Yup.string().required("This Field is required."),
    address: Yup.string().required("This Field is required."),
    pic: Yup.string().required("This Field is required."),
    contact: Yup.string().required("This Field is required.").matches(phoneRegExp, "Phone number is not valid"),
    remark: Yup.string().required("This Field is required."),
});
