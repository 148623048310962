import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md";

import { HomeLayout, CardTable } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { tHeader } from "../utilities/tableData";

import MediaDeletePopup from "../mediaDeletePopup/mediaDeletePopup";

class SuperAdminMediaDashboard extends Component {
    // 1 - URL, 12 - URL&PDF(Article), 4 - PDF(research)

    constructor(props) {
        super(props);
        this.state = {
            listLenght: 50,
            videoList: [],
            articleList: [],
            researchList: [],
            removeModalStatus: false,
            removeDataSelected: null,
        };
    }

    componentDidMount() {
        this.requestVideo();
        this.requestArtice();
        this.requestResearch();
    }

    requestVideo = () => {
        super_Api_Req.get
            .articleList(3, this.state.listLenght)
            .then((resp) => {
                this.setState({
                    videoList: resp.data.articles,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    requestArtice = () => {
        super_Api_Req.get
            .articleList(12, this.state.listLenght)
            .then((resp) => {
                this.setState({
                    articleList: resp.data.articles,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    requestResearch = () => {
        super_Api_Req.get
            .articleList(4, this.state.listLenght)
            .then((resp) => {
                this.setState({
                    researchList: resp.data.articles,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    navigateTo = (type) => {
        const { history } = this.props;
        if (type === 3) {
            history.push("super-media-add-video");
        } else if (type === 12) {
            history.push("super-media-add-article");
        } else if (type === 4) {
            history.push("super-media-add-research");
        }
    };

    onEdit = (data) => {
        const { history } = this.props;
        if (data.articleType === 3) {
            history.push("super-media-edit-video", { id: data.id });
        } else if (data.articleType === 1 || data.articleType === 2) {
            history.push("super-media-edit-article", { id: data.id });
        } else if (data.articleType === 4) {
            history.push("super-media-edit-research", { id: data.id });
        }
    };

    onDelete = (data) => {
        this.setState({
            ...this.state,
            removeDataSelected: data,
            removeModalStatus: !this.state.removeModalStatus,
        });
    };

    deleteArticle = () => {
        const data = this.state.removeDataSelected;
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("active", false);

        super_Api_Req.post
            .editArticle(formData)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                if (data.articleType === 3) {
                    this.requestVideo();
                } else if (data.articleType === 1 || data.articleType === 2) {
                    this.requestArtice();
                } else if (data.articleType === 4) {
                    this.requestResearch();
                }
            })
            .catch((err) => {
                this.props.alertError(err);
            });

        this.modalToggle();
    };

    modalToggle = () => {
        this.setState({
            ...this.state,
            removeDataSelected: null,
            removeModalStatus: false,
        });
    };

    render() {
        const { videoList, articleList, researchList, removeModalStatus } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-media-dashboard-container">
                    <div className="media-header-container">
                        <h1 className="media-title">
                            VIDEO LIBRARY
                            <Icon className="add-icon" icon={ic_add_box} size={20} onClick={() => this.navigateTo(3)} />
                        </h1>
                    </div>
                    <div>
                        <CardTable tableTHeadData={tHeader.videoList} tableTBodyData={videoList} editAppt={this.onEdit} onDelete={this.onDelete} />
                    </div>
                    <div className="media-header-container media-header-container--with-top">
                        <h1 className="media-title">
                            ARTICLE LIBRARY
                            <Icon className="add-icon" icon={ic_add_box} size={20} onClick={() => this.navigateTo(12)} />
                        </h1>
                    </div>
                    <div>
                        <CardTable tableTHeadData={tHeader.articleList} tableTBodyData={articleList} editAppt={this.onEdit} onDelete={this.onDelete} />
                    </div>
                    <div className="media-header-container media-header-container--with-top">
                        <h1 className="media-title">
                            RESEARCH PAPER LIBRARY
                            <Icon className="add-icon" icon={ic_add_box} size={20} onClick={() => this.navigateTo(4)} />
                        </h1>
                    </div>
                    <div>
                        <CardTable tableTHeadData={tHeader.articleList} tableTBodyData={researchList} editAppt={this.onEdit} onDelete={this.onDelete} />
                    </div>

                    <MediaDeletePopup removeModalStatus={removeModalStatus} modalToggle={this.modalToggle} deleteArticle={this.deleteArticle} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminMediaDashboard);
