import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { caretLeft, caretRight } from "react-icons-kit/fa/";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { HomeLayout, PopUp, Title } from "components";
import { api_Req, timeSlotData } from "common/services";
import { actions } from "../../../../redux/actions/actions";

const localizer = momentLocalizer(moment);
class AppointmentCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            appointmentVisible: false,
            appointmentData: null,
            currentDate: new Date(),
        };
    }

    componentDidMount() {
        const year = moment().format("YYYY");
        const month = moment().format("MM");
        this.calendarRecordOfTheMonth(month, year);
    }

    calendarChangeMonth = (date, view, action) => {
        let month = null;
        let year = null;

        if (typeof action !== "string") {
            year = moment(action).format("YYYY");
            month = moment(action).format("MM");
            this.setState({ currentDate: action });
        } else {
            year = moment(date).format("YYYY");
            month = moment(date).format("MM");
            this.setState({ currentDate: date });
        }
        this.calendarRecordOfTheMonth(month, year);
    };

    calendarRecordOfTheMonth = (month, year) => {
        api_Req.get
            .apptRecord(month, year)
            .then((resp) => {
                console.log(resp);
                this.filterEvent(resp.data);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    filterEvent = (data) => {
        const eventsData = [];
        try {
            data.forEach((o, i) => {
                const totalSlot = 6;
                const date = moment(o.appointmentDate).format("YYYY-MM-DD");

                [...Array(totalSlot).keys()].forEach((k) => {
                    const slot = o[`slot${k + 1}`];
                    if (slot && slot.length)
                        eventsData.push({
                            id: k + 1,
                            title: timeSlotData[k].label,
                            start: moment(`${date} ${timeSlotData[k]?.value?.start}:00`).toDate(),
                            end: moment(`${date} ${timeSlotData[k]?.value?.end}:00`).toDate(),
                            slots: slot,
                        });
                });
            });
            this.setState({ events: eventsData });
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = ({ start }) => {
        const { history } = this.props;
        const today = moment();
        const expired = today.diff(start, "days");
        if (expired <= 0) {
            history.push("createappointment", { appointmentDate: start });
        }
    };

    onSelectEvent = (selectedData) => {
        if (selectedData.slots && selectedData.slots.length > 1) {
            this.setState({ appointmentData: selectedData, appointmentVisible: true });
        } else {
            this.onHandleNavigateEvent({ ...selectedData, ...selectedData.slots[0] });
        }
    };

    onHandleNavigateEvent = (data, index) => {
        const { history } = this.props;
        let appointment = data;

        if (index !== null) {
            appointment = { ...appointment, ...data.slots[index] };
        }

        history.push("editappointment", { apptData: appointment });
    };

    toggleAppointment = () => {
        this.setState({ appointmentVisible: !this.state.appointmentVisible });
    };

    render() {
        const { events, appointmentData } = this.state;
        return (
            <HomeLayout>
                <PopUp visible={this.state.appointmentVisible} toggle={this.toggleAppointment}>
                    <div className="popup-container-order">
                        <Title title={appointmentData?.start && moment(appointmentData?.start).format("DD/MM/YYYY") + ", " + appointmentData?.title} />
                        <div className="popup-content">
                            {appointmentData?.slots?.map((slot, index) => {
                                return (
                                    <button className="popup-order-button" key={index} onClick={() => this.onHandleNavigateEvent(appointmentData, index)}>
                                        {slot.childrenName}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </PopUp>
                <div className="rbc-calendar">
                    <Calendar
                        selectable
                        popup={true}
                        step={3}
                        localizer={localizer}
                        events={events}
                        longPressThreshold={0}
                        components={{ toolbar: CustomToolbar }}
                        defaultDate={this.state.currentDate}
                        date={this.state.currentDate}
                        onNavigate={this.calendarChangeMonth}
                        onSelectSlot={(props) => this.handleSelect(props)}
                        onSelectEvent={this.onSelectEvent}
                        eventPropGetter={(event, start, end, isSelected) => {
                            if (event.status === 1) {
                                return { style: { backgroundColor: "red" } };
                            }
                        }}
                    />
                </div>
            </HomeLayout>
        );
    }
}

class CustomToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    onHandleSetMonth = (month) => {
        const today = new Date();
        today.setMonth(month);
        this.props.onNavigate(today);
        this.toggleMonths();
    };

    toggleMonths = () => {
        this.setState({ visible: !this.state.visible });
    };

    navigate = (action) => {
        this.props.onNavigate(action);
    };

    render() {
        return (
            <div className="rbc-toolbar">
                <PopUp visible={this.state.visible} toggle={this.toggleMonths}>
                    <div className="popup-container-months">
                        <Title title="Select month" />
                        <div className="popup-content">
                            {moment.months().map((month, key) => {
                                return (
                                    <button className="popup-months-button" key={month} onClick={() => this.onHandleSetMonth(key)}>
                                        {month}
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </PopUp>
                <span className="rbc-btn-group">
                    <Icon className="icon-left" icon={caretLeft} size={30} onClick={() => this.navigate("PREV")}>
                        <button type="button" />
                    </Icon>
                    <span className="rbc-toolbar-label" onClick={this.toggleMonths}>
                        {this.props.label}
                    </span>
                    <Icon className="icon-right" icon={caretRight} size={30} onClick={() => this.navigate("NEXT")}>
                        <button type="button" />
                    </Icon>
                </span>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(null, mapDispatchToProps)(AppointmentCalendar);
