import React from "react";
import moment from "moment";

export const tHeader = {
    redemptionsList: [
        {
            label: "DATE REDEEM",

            key: "dateRedeem",
            format: "date",
        },

        {
            label: "NAME",
            key: "customerName",
        },

        {
            label: "ITEMS",
            key: "itemName",
        },
        {
            label: "POINTS",
            key: "points",
        },

        {
            label: "",
            key: "edit",
        },
    ],

    redemptionsDetails: [
        {
            label: "DATE REDEEM",

            key: "dateRedeem",
            format: "date",
        },
        {
            label: "ITEM",
            key: "itemName",
        },
        {
            label: "QTY",
            key: "quantity",
        },
        {
            label: "POINTS (PT)",
            key: "points",
        },
    ],
};

export const shippingDetails = [
    {
        label: "FULL NAME",
        desc: "TAN KIM MUI",
        icon: "",
        name: "name",
    },

    {
        label: "CONTACT NUMBER",
        desc: "+6012345678",
        icon: "",
        name: "phoneNumber",
    },

    {
        label: "SHIPPING ADDRESS",
        desc: "B-05-3A, Menara Bata (Tower B), PJ Trade Centre, No. 8, Jalan PJU 8/8A, Bandar Damansara Perdana, PJU 8, 47820 Petaling Jaya, Selangor Darul Ehsan.",
        icon: "",
        name: "address",
    },
];

export const pointsData = [
    {
        label: "Available Points",
        desc: <div>(updated at {moment().format("DD/MM/YYYY")})</div>,
        points: 99,
        icon: "",
        name: "address",
    },
];
