import React, { Component } from "react";
import { connect } from "react-redux";

import { HomeLayout, CardTable, PromoCard } from "components";
import { tHeader } from "./utilities/tableData";
import { super_Api_Req } from "common/services";
import { actions } from "../../../redux/actions/actions";

class SuperAdminHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newsLists: [],
            csvList: [],
            bannerList: [],
        };
    }

    setNewsVisibility = (o) => {
        const payload = {
            NewsId: o.newsId,
            ToShow: !o.active,
        };
        super_Api_Req.put
            .newsVisibility(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    navigateTo = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    addNewCsv = () => {
        const { history } = this.props;
        history.push("super-add-csv");
    };

    onEditPromo = (data) => {
        const { history } = this.props;
        history.push("super-edit-promo", { data: data });
    };

    onDeletePromo = (data) => {
        const payload = {
            BannerId: data.bannerId,
        };
        super_Api_Req.put
            .deleteBanner(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const {
            superSummary: { news, csv, banners },
        } = this.props;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-home-container">
                    <PromoCard title="CLIENT PROMOTION" onHeaderClick={() => this.navigateTo("super-add-promo")} onEdit={this.onEditPromo} onDelete={this.onDeletePromo} promoData={banners} />
                    <CardTable title="ECP NEWS LIST" tableTHeadData={tHeader.newsList} tableTBodyData={news} onClick={this.setNewsVisibility} onHeaderClick={() => this.navigateTo("super-add-news")} />
                    <CardTable title="CSV FILE" tableTHeadData={tHeader.csvFileList} tableTBodyData={csv} onHeaderClick={this.addNewCsv} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminHome);
