import React, { Component } from "react";
import { connect } from "react-redux";
import { HomeLayout, CardTable } from "components";
import { super_Api_Req } from "common/services";

import { actions } from "../../../../redux/actions/actions";
import { tHeader } from "../utilities/tableData";

class SuperAdminRedemptionsDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redemptionsList: [],
        };
    }

    componentDidMount() {
        this.requestRedemptions();
    }

    requestRedemptions = () => {
        super_Api_Req.get
            .redemptionsList()
            .then((resp) => {
                this.setState({
                    redemptionsList: resp.data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };
    // onEdit = data => {
    //   const { history } = this.props;
    //   history.push("super-redemptions-dashboard", { data: data });
    // };

    // navigateTo = () => {
    //   const { history } = this.props;
    //   history.push("super-ecp-add");
    // };

    onEdit = (data) => {
        const { history } = this.props;
        history.push("super-redemptions-details", { data: data });
    };

    render() {
        const { redemptionsList } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-redemptions-dashboard-container">
                    <CardTable title="Redemptions" tableTHeadData={tHeader.redemptionsList} tableTBodyData={redemptionsList} editAppt={this.onEdit} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminRedemptionsDashboard);
