import { call, put, takeLatest } from "redux-saga/effects";
import { super_Api_Req } from "common/services";
import { actions } from "../actions/actions";

function* fetchSuperSummary(action) {
    try {
        const { payload } = action;
        const resp = yield call(super_Api_Req.get.summaryHome);
        yield put({ type: actions.SET_SUPER_SUMMARY, data: resp.data });
        payload && payload.history && payload.history.push("super-home");
    } catch (err) {
        yield put({ type: actions.ERROR_ALERT, data: err });
    }
}

function* superSummarySaga() {
    yield takeLatest(actions.FETCH_SUPER_SUMMARY, fetchSuperSummary);
}

export default superSummarySaga;
