import React, { Component } from "react";
import { Layout, Title, Button, LoginCard } from "components";

class resetPasswordCompleted extends Component {
    proceedToLogin = () => {
        const { history } = this.props;
        history.push("login");
    };

    render() {
        return (
            <Layout>
                <div className="resetpass_container">
                    <LoginCard>
                        <div className="reset-password-content">
                            <Title title="YOUR PASSWORD HAS BEEN RESET" />
                            <p className="resetpass_text">Your password has been successfully changed. You can now login using your new password</p>
                            <Button title="LOGIN" onClick={this.proceedToLogin} />
                        </div>
                    </LoginCard>
                </div>
            </Layout>
        );
    }
}

export default resetPasswordCompleted;
