export const tHeader = {
    ecpList: [
        {
            label: "DATE",
            key: "date",
            format: "date",
        },
        {
            label: "ECP NO.",
            key: "ecpId",
        },
        {
            label: "OUTLET",
            key: "name",
        },
        {
            label: "PIC",
            key: "personInCharge",
        },
        {
            label: "CONTACT NO.",
            key: "mobileNumber",
        },
        {
            label: "EMAIL",
            key: "email",
        },
        {
            label: "",
            key: "edit",
        },
    ],
};

export const tBody = {
    newsList: [
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
    ],
};
