import React, { Fragment, PureComponent } from "react";
import { Card, CardBody, Label } from "reactstrap";
import Icon from "react-icons-kit";
import { userO } from "react-icons-kit/fa/userO";
import { phone } from "react-icons-kit/feather/phone";
import { location } from "react-icons-kit/oct/location";
import { flag } from "react-icons-kit/feather/flag";
import { Formik } from "formik";
import { lockClosedOutline, mail } from "react-icons-kit/typicons";
import { pencil } from "react-icons-kit/typicons";
import { clock, globe } from "react-icons-kit/feather";

import { PopUp, Title, Button } from "components";
import { inputField, inputValidator } from "./InputData/inputData";

export default class CorporateCard extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalLabel: {
                label: "",
            },
        };
    }

    toggle = (inputType) => {
        if (inputType !== "close") {
            const modalLabel = inputField.filter((i) => {
                return i.name === inputType;
            });
            this.setState((prevState) => ({
                modal: !prevState.modal,
                inputType: inputType,
                modalLabel: modalLabel[0],
            }));
        } else {
            this.setState((prevState) => ({
                modal: !prevState.modal,
            }));
        }
    };

    initialInputValue = () => {
        const { inputType } = this.state;
        const { userProfile } = this.props;
        const inputData = inputField.filter((i) => {
            return i.name === inputType;
        });

        if (inputType) {
            return { [inputData[0].key]: userProfile[inputType] };
        }
    };

    onSubmit = (values) => {
        const { inputType } = this.state;
        if (inputType === "businessHour") {
            let payload = [...values.BusinessHour];
            const keys = Object.keys(values);
            keys.filter((i) => {
                return i !== "BusinessHour";
            }).forEach((i) => {
                payload[i] = values[i];
            });
            this.props.onSave({ BusinessHour: payload });
        } else {
            this.props.onSave(values);
        }
        this.toggle("close");
    };

    renderInput = ({ touched, errors, handleBlur, handleChange, values }) => {
        const { inputType } = this.state;

        let input = null;

        inputField.forEach((data, i) => {
            if (inputType === "businessHour" && data.name === inputType) {
                input = data.specialInput.map((o, j) => {
                    return (
                        <div className="input-container" key={`${o.name}_input_${j}`}>
                            <Label className="input-label">{o.label}</Label>
                            <input
                                type="text"
                                name={j}
                                value={values[j] || values.BusinessHour[j]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder={o.label}
                                autoComplete="off"
                                className={errors[j] && values[j] ? "text-input input-business-hrs error" : "text-input input-business-hrs"}
                            />
                            {errors[j] && values[j] && <div className="input-feedback">{errors[j]}</div>}
                        </div>
                    );
                });
            } else if (inputType === "password" && data.name === inputType) {
                input = data.specialInput.map((o, j) => {
                    const name = o.name;
                    return (
                        <div className="input-container" key={`${name}_input_${j}`}>
                            <input
                                type={"password"}
                                name={name}
                                value={values[name]}
                                placeholder={o.label}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                                className={errors[name] && touched[name] ? "text-input error" : "text-input"}
                            />
                            {errors[name] && <div className="input-feedback">{errors[name]}</div>}
                        </div>
                    );
                });
            } else if (data.name === inputType) {
                input = (
                    <Fragment key={`${data.name}_input_${i}`}>
                        <input
                            type={data.type || "text"}
                            id={data.name}
                            name={data.key}
                            placeholder={data.label}
                            value={values[data.key]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            autoComplete="off"
                            className={errors[data.key] && touched[data.key] ? "text-input error" : "text-input"}
                        />
                        {errors[data.key] && <div className="input-feedback">{errors[data.key]}</div>}
                    </Fragment>
                );
            }
        });

        return input;
    };

    render() {
        const { userProfile } = this.props;
        const { modal, modalLabel, inputType } = this.state;
        return (
            <div className="corpmain_container">
                <div className="card_container_one">
                    <Card>
                        <CardBody className="card_body">
                            <div className="corp_container">
                                <div className="corpleft_container">
                                    <Icon icon={userO} size={18} />
                                    <Label>CORPORATE NAME</Label>
                                    <p className="crop-text">{userProfile.corporateName}</p>
                                </div>
                                <div className="corpright_container">
                                    <Label>BRANCH NUMBER</Label>
                                    <p className="crop-text">{userProfile.branchNumber}</p>
                                </div>
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={userO} size={18} />
                            <div className="contact_container">
                                <div>
                                    <Label>PERSON IN CHARGE</Label>
                                    <p className="crop-text">{userProfile.personInCharge}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("personInCharge")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={phone} size={18} />
                            <div className="contact_container">
                                <div>
                                    <Label>CONTACT NUMBER</Label>
                                    <p className="crop-text">{userProfile.mobileNumber}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("mobileNumber")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={phone} size={18} />
                            <div className="fax_container">
                                <div>
                                    <Label>FAX</Label>
                                    <p className="crop-text">{userProfile.fax}</p>
                                </div>
                                <Icon className="edit-button icon_edit" icon={pencil} size={20} onClick={() => this.toggle("fax")} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={globe} size={18} />
                            <div className="website_container">
                                <div>
                                    <Label>WEBSITE</Label>
                                    <p className="crop-text">{userProfile.website}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("website")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <div className="businesshour_container">
                                <Icon className="left_icon" icon={clock} size={18} />
                                <Label className="b_label">BUSINESS HOUR</Label>
                                <div className="businesshour_details">
                                    {userProfile.businessHour.map((time, i) => {
                                        const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                                        const classes = time.toLowerCase() === "closed" ? "sunday_closed crop-text" : "crop-text";
                                        return (
                                            <div className="business-hrs-row" key={`${time}_${i}`}>
                                                <p className="crop-text">{days[i]}</p>
                                                <p className={classes}>{time}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("businessHour")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={location} size={18} />
                            <div className="address_container">
                                <div>
                                    <Label>ADDRESS</Label>
                                    <p className="crop-text">{userProfile.address}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("address")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody className="card_body">
                            <Icon className="left_icon" icon={flag} size={18} />
                            <div className="country_container">
                                <div>
                                    <Label>COUNTRY/REGION</Label>
                                    <p className="crop-text">{userProfile.country}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("country")} size={20} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className="card_container_two">
                    <Card className="smCard_Container">
                        <CardBody>
                            <Icon className="left_icon" icon={mail} size={25} />
                            <div className="email_container">
                                <div>
                                    <Label>EMAIL</Label>
                                    <p className="crop-text">{userProfile.email}</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("email")} size={20} />
                            </div>
                        </CardBody>
                        <CardBody>
                            <Icon className="left_pass_icon" icon={lockClosedOutline} size={25} />
                            <div className="password_container">
                                <div>
                                    <Label>PASSWORD</Label>
                                    <p className="text_resetpass">Reset Password</p>
                                </div>
                                <Icon className="edit-button" icon={pencil} onClick={() => this.toggle("password")} size={20} />
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <PopUp visible={modal} className="corp-modal-pop-up">
                    <div className="popUp_container">
                        <Title title={modalLabel.label || ""} />
                        <div className="popUp_content">
                            <Formik
                                initialValues={this.initialInputValue()}
                                validationSchema={inputValidator[inputType]}
                                onSubmit={(values) => {
                                    this.onSubmit(values);
                                }}>
                                {(props) => {
                                    const { isSubmitting, handleSubmit } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="formStyle">
                                            {this.renderInput(props)}
                                            <div className="button-container">
                                                <Button type="submit" title="UPDATE" disabled={isSubmitting} />
                                                <Button type="button" title="CANCEL" outline onClick={() => this.toggle("close")} />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </PopUp>
            </div>
        );
    }
}
