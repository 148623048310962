import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { authExist } from "common/helper/utilities";

const SuperPublicRoute = ({ component: Component, restricted, ...rest }) => {
    const userProfileExist = rest.userProfile.auth;
    const token = userProfileExist ? rest.userProfile.auth.token : null;
    return <Route {...rest} render={(props) => (authExist(token) && restricted ? <Redirect to="/super-home" /> : <Component {...props} />)} />;
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
    };
};

export default connect(mapStateToProps, null)(SuperPublicRoute);
