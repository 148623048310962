import { actions } from "../actions/actions";

const initialState = {
    msg: "",
    type: "",
};

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.CLEAR_ALERT:
            return initialState;
        case actions.SUCCESS_ALERT:
            return {
                ...state,
                msg: action.data,
                type: true,
            };
        case actions.ERROR_ALERT:
            return {
                ...state,
                msg: action.data.response && action.data.response.data && action.data.response.data.message ? action.data.response.data.message : action.data.message,
                type: false,
            };
        default:
            return state;
    }
};
