import { eye, eyeDisabled } from "react-icons-kit/ionicons/";

export const tHeader = {
    newsList: [
        {
            label: "POSTED DATE",
            key: "postedDate",
            format: "date",
        },
        {
            label: "NEWS",
            key: "message",
        },
        {
            label: "",
            icon: eye,
            disabledIcon: eyeDisabled,
            key: "eyes",
        },
    ],
    csvFileList: [
        {
            label: "DATE",
            key: "date",
            format: "date",
        },
        {
            label: "FILE NAME",
            key: "fileName",
        },
    ],
};

export const tBody = {
    newsList: [
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
    ],
};
