export const tHeader = {
    productLists: [
        {
            label: "DATE OF PURCHASE",
            key: "dateOfPurchase",
            format: "date",
        },
        {
            label: "ECP NO.",
            key: "ecpId",
        },
        {
            label: "ORDER NO.",
            key: "orderNumber",
        },
        {
            label: "RIGHT(SPH,CYL,ADD)",
            key: "right",
        },
        {
            label: "LEFT(SPH,CYL,ADD)",
            key: "left",
        },
        {
            label: "",
            key: "edit",
        },
    ],
};

export const tBody = {
    newsList: [
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
        {
            postedDate: "POSTED DATE",
            news: "News",
        },
    ],
};
