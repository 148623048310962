import React, { Component } from "react";
import { Alert } from "reactstrap";
import Icon from "react-icons-kit";
import { closeRound } from "react-icons-kit/ionicons/";
import { connect } from "react-redux";
import { actions } from "../../../redux/actions/actions";
import "./alert.scss";

class AlertComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.msg && !prevState.visible) {
            this.setState({ visible: true }, () => {
                setTimeout(() => {
                    this.props.alertError();
                    this.setState({ visible: false });
                }, 10000);
            });
        }
    }

    render() {
        const { type, msg } = this.props;
        const color = type ? "success" : "danger";

        if (msg) {
            return (
                <Alert className="alert-container" color={color}>
                    {msg}
                    <Icon icon={closeRound} onClick={this.props.alertError} size={13} />
                </Alert>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: () => {
        dispatch({ type: actions.CLEAR_ALERT });
    },
});

export default connect(null, mapDispatchToProps)(AlertComponent);
