import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { api_Req } from "common/services";
import { actions } from "../../redux/actions/actions";
import { Layout, Title, Button, LoginCard } from "components";

class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = async (values) => {
        const payload = {
            ECP: values.ecp,
            Email: values.email,
        };

        try {
            const response = await api_Req.post.forgotPassword(payload);
            this.props.alertSuccess(response.data.message);
            this.props.history.push("admin");
        } catch (error) {
            this.props.alertError(error);
        }
    };

    render() {
        return (
            <Layout>
                <div className="reset-password_container">
                    <LoginCard>
                        <div className="reset-password_content">
                            <Title title="FORGET PASSWORD" />
                            <p className="forgotpass_text">Enter your ECP number</p>
                            {alert.message && <div className={`alert ${alert.type}`}>{alert.message}</div>}
                            <Formik
                                initialValues={{ ecp: "", email: "" }}
                                validationSchema={Yup.object().shape({
                                    ecp: Yup.string().required("This field is required"),
                                    email: Yup.string().email().required("This field is required"),
                                })}
                                onSubmit={(values) => {
                                    this.handleSubmit(values);
                                }}>
                                {(props) => {
                                    const { touched, errors, handleBlur, handleChange, handleSubmit, values } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="formStyle">
                                            <div className="icon_container">
                                                <input name="ecp" placeholder="ECP Number" value={values.ecp} onChange={handleChange} onBlur={handleBlur} autoComplete="off" className={errors.ecp && touched.ecp ? "text-input error" : "text-input"} />
                                            </div>
                                            {errors.ecp && touched.ecp && <div className="input-feedback">{errors.ecp}</div>}
                                            <div className="icon_container">
                                                <input
                                                    name="email"
                                                    placeholder="Email Address"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                    className={errors.email && touched.email ? "text-input error" : "text-input"}
                                                />
                                            </div>
                                            {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}

                                            <Button className="reset-button" type="submit" title="RESET PASSWORD" />
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </LoginCard>
                </div>
            </Layout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
