export const clientListData = [
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "TAN BOON KEAT",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
        icon: "",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
    {
        orderNo: 12345678,
        dateOfPurchase: "15/11/2019",
        registeredName: "XXXXXXXXX",
        contactNo: "+60123456789",
        lastAppointment: "15/11/2019",
    },
];

export const tableNewHeader = {
    clientListHeader: [
        {
            label: "Order No.",
            key: "orderNumber",
        },
        {
            label: "Date of Purchase",
            key: "dateOfPurchased",
            format: "date",
        },
        {
            label: "Registered Name",
            key: "ownerName",
        },
        {
            label: "Contact No",
            key: "mobileNumber",
        },
        {
            label: "Last Appointment",
            key: "lastAppointment",
            format: "date",
        },
        {
            label: "",
            key: "view",
        },
    ],
};
