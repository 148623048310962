import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import PrivateRoute from "./admin/PrivateRoute/PrivateRoute";
import PublicRoute from "./admin/PublicRoute/PublicRoute";
import SuperPrivateRoute from "./super-admin/PrivateRoute/PrivateRoute";
import SuperPublicRoute from "./super-admin/PublicRoute/PublicRoute";
import { history } from "../common/helper/history";
import Login from "../pages/admin/login";
import ResetPassword from "../pages/admin/resetPassword";
import resetPasswordCompleted from "../pages/admin/resetPasswordCompleted";
import ForgetPassword from "../pages/admin/forgetPassword";
import Admin from "../pages/admin/adminHome/adminHome";
import ContactUs from "../pages/admin/contactUs";
import Client from "../pages/admin/clientDetails";
import ClientList from "../pages/admin/clientList/clientList";
import CreateAppointment from "../pages/admin/appointment/createAppt/createAppt";
import WarrantyRequest from "../pages/admin/appointment/warranty-request/warranty-request";
import EditAppointment from "../pages/admin/appointment/editAppt/editAppt";
import AppointmentHistory from "../pages/admin/appointment/apptHistory/apptHistory";
import CorporateInfo from "../pages/admin/corporateInfo";
import AppointmentCalendar from "../pages/admin/appointment/apptCalendar/apptCalendar";
import {
    SuperLogin,
    SuperHome,
    SuperAddNews,
    SuperAddPromotion,
    SuperEditPromotion,
    SuperECPDashboard,
    SuperECPEdit,
    SuperECPAdd,
    SuperRewardsDashboard,
    SuperRewardsAdd,
    SuperRewardsEdit,
    SuperProductDashboard,
    SuperProductDetails,
    SuperRedemptionsDetails,
    SuperRedemptionsDashboard,
    SuperAddCsvFile,
    SuperMediaDashboard,
    SuperMediaAddVideo,
    SuperMediaEditVideo,
    SuperMediaAddArticle,
    SuperMediaEditArticle,
    SuperMediaAddResearch,
    SuperMediaEditResearch,
} from "../pages/super-admin";

import { Alert } from "components";

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { alert } = this.props;
        return (
            <Router history={history}>
                <Alert type={alert.type} msg={alert.msg} />
                <Switch>
                    <PublicRoute exact path="/admin" component={Login} restricted={true} />
                    <Route path="/resetPasswordCompleted" component={resetPasswordCompleted} />
                    <Route path="/resetpassword" component={ResetPassword} />
                    <PublicRoute path="/forgetpassword" component={ForgetPassword} restricted={true} />
                    <PrivateRoute path="/adminhome" component={Admin} />
                    <PrivateRoute path="/corporateinfo" component={CorporateInfo} />
                    <PrivateRoute path="/clientDetails" component={Client} />
                    <PrivateRoute path="/clientlist" component={ClientList} />
                    <PrivateRoute path="/contactus" component={ContactUs} />
                    <PrivateRoute path="/createappointment" component={CreateAppointment} />
                    <PrivateRoute path="/editappointment" component={EditAppointment} />
                    <PrivateRoute path="/warranty-request" component={WarrantyRequest} />
                    <PrivateRoute path="/appointmenthistory" component={AppointmentHistory} />
                    <PrivateRoute path="/appointmentcalendar" component={AppointmentCalendar} />

                    {/* SUPER ADMIN */}
                    <SuperPublicRoute path="/super-admin" component={SuperLogin} restricted={true} />
                    <SuperPrivateRoute path="/super-home" component={SuperHome} />
                    <SuperPrivateRoute path="/super-add-news" component={SuperAddNews} />
                    <SuperPrivateRoute path="/super-add-promo" component={SuperAddPromotion} />
                    <SuperPrivateRoute path="/super-add-csv" component={SuperAddCsvFile} />
                    <SuperPrivateRoute path="/super-edit-promo" component={SuperEditPromotion} />
                    <SuperPrivateRoute path="/super-ecp-dashboard" component={SuperECPDashboard} />
                    <SuperPrivateRoute path="/super-ecp-edit" component={SuperECPEdit} />
                    <SuperPrivateRoute path="/super-ecp-add" component={SuperECPAdd} />
                    <SuperPrivateRoute path="/super-rewards-dashboard" component={SuperRewardsDashboard} />
                    <SuperPrivateRoute path="/super-rewards-add" component={SuperRewardsAdd} />
                    <SuperPrivateRoute path="/super-rewards-edit" component={SuperRewardsEdit} />
                    <SuperPrivateRoute path="/super-rewards-edit" component={SuperRewardsEdit} />
                    <SuperPrivateRoute path="/super-prod-dashboard" component={SuperProductDashboard} />
                    <SuperPrivateRoute path="/super-prod-details" component={SuperProductDetails} />
                    <SuperPrivateRoute path="/super-redemptions-details" component={SuperRedemptionsDetails} />
                    <SuperPrivateRoute path="/super-redemptions-dashboard" component={SuperRedemptionsDashboard} />
                    <SuperPrivateRoute path="/super-media-dashboard" component={SuperMediaDashboard} />
                    <SuperPrivateRoute path="/super-media-add-video" component={SuperMediaAddVideo} />
                    <SuperPrivateRoute path="/super-media-edit-video" component={SuperMediaEditVideo} />
                    <SuperPrivateRoute path="/super-media-add-article" component={SuperMediaAddArticle} />
                    <SuperPrivateRoute path="/super-media-edit-article" component={SuperMediaEditArticle} />
                    <SuperPrivateRoute path="/super-media-add-research" component={SuperMediaAddResearch} />
                    <SuperPrivateRoute path="/super-media-edit-research" component={SuperMediaEditResearch} />
                    <Redirect from="*" to="/admin" />
                </Switch>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        userProfile: state.userProfile,
    };
};

export default connect(mapStateToProps, null)(Navigation);
