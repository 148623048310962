import React, { Component } from "react";
import { connect } from "react-redux";
import { Calendar } from "react-calendar";
import moment from "moment";
import Icon from "react-icons-kit";
import { Label, Input } from "reactstrap";
import { chevronLeft } from "react-icons-kit/ionicons/";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import { ic_chevron_left, ic_chevron_right } from "react-icons-kit/md/";

import { HomeLayout, Button, Title } from "components";
import { super_Api_Req } from "common/services";

import { actions } from "../../../redux/actions/actions";

const initialState = {
    calendarVisible: false,
    form: {
        date: new Date(),
        msg: "",
    },
};
class SuperAdminAddNews extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleChange = (val, name) => {
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [name]: val,
            },
        }));
    };

    toggleDate = () => {
        this.setState((prevState) => ({
            calendarVisible: !prevState.calendarVisible,
        }));
    };

    onSubmit = () => {
        const { form } = this.state;

        if (form.msg) {
            const payload = {
                Message: form.msg,
                Date: moment(form.date).format("YYYY-MM-DD"),
            };
            super_Api_Req.post
                .addNews(payload)
                .then((resp) => {
                    this.setState(initialState);
                    this.props.fetchSuperSummary();
                    this.props.alertSuccess(resp.data.message);
                })
                .catch((err) => {
                    this.props.alertError(err);
                });
        }
    };

    render() {
        const { calendarVisible, form } = this.state;
        return (
            <HomeLayout role="super-admin">
                {calendarVisible && <div id="overlay" onClick={this.toggleDate} />}
                <div className="super-container s-add-news-container">
                    <div className="title-container">
                        <Title title="ADD NEWS" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>
                    <div className="input-container">
                        <Label>Select Date</Label>
                        <div className="date-input-wrapper" onClick={this.toggleDate}>
                            <Icon icon={ic_expand_more} className="icon-chevron" size={25} />
                            <Input className="date-time-input" value={moment(form.date).format("DD/MM/YYYY")} maxLength={20} readOnly />
                        </div>
                    </div>
                    <div className="input-container">
                        <Label>Select Date</Label>
                        <textarea onChange={(e) => this.handleChange(e.currentTarget.value, "msg")} value={form.msg} className="msg_box" placeholder="Message" cols="50" rows="4" />
                    </div>
                    <div className="button-container">
                        <Button title="SAVE" onClick={this.onSubmit} disabled={!form.msg} />
                        <Button title="CANCEL" outline onClick={() => this.props.history.push("super-home")} />
                    </div>
                    {calendarVisible && (
                        <div className="calendar-container">
                            <div className="calendar-wrapper">
                                <Calendar
                                    locale="US"
                                    minDate={new Date()}
                                    onChange={(v) => this.handleChange(v, "date")}
                                    value={form.date || new Date()}
                                    prevLabel={<Icon icon={ic_chevron_left} size={20} className="calendar-button" />}
                                    nextLabel={<Icon icon={ic_chevron_right} size={20} className="calendar-button" />}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminAddNews);
