import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { ic_add_box } from "react-icons-kit/md";
import { search } from "react-icons-kit/icomoon/search";
import { Label, Input } from "reactstrap";

import { HomeLayout, CardTable } from "components";
import { super_Api_Req } from "common/services";

import { actions } from "../../../../redux/actions/actions";
import { tHeader } from "../utilities/tableData";

class SuperAdminECPDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ecpList: [],
            ecpListData: [],
        };
    }

    componentDidMount() {
        this.requestECP();
    }

    searchECPno = (e) => {
        const value = e.currentTarget.value;
        const { ecpListData } = this.state;
        const ecpList = ecpListData.filter((o) => {
            return o.ecpId.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        });

        this.setState({ ecpList });
    };

    requestECP = () => {
        super_Api_Req.get
            .ecpLists()
            .then((resp) => {
                this.setState({
                    ecpList: resp.data,
                    ecpListData: resp.data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    navigateTo = () => {
        const { history } = this.props;
        history.push("super-ecp-add");
    };

    onEdit = (data) => {
        const { history } = this.props;
        history.push("super-ecp-edit", { data: data });
    };

    render() {
        const { ecpList, ecpListData } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-ecp-dashboard-container">
                    <div className="ecp-header-container">
                        <h1 className="ecp-title">
                            ECP LIST
                            <Icon className="add-icon" icon={ic_add_box} size={20} onClick={this.navigateTo} />
                        </h1>
                    </div>
                    <div className="search-container">
                        <Label>ECP NUMBER</Label>
                        <Icon icon={search} className="icon-search" />
                        <Input className="search-input" type="text" placeholder="SEARCH" onChange={this.searchECPno} disabled={!ecpListData.length} />
                    </div>
                    <CardTable tableTHeadData={tHeader.ecpList} tableTBodyData={ecpList} editAppt={this.onEdit} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminECPDashboard);
