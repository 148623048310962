import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import Icon from "react-icons-kit";
import { trashA } from "react-icons-kit/ionicons/";
import Dropzone from "react-dropzone";
import moment from "moment";

import { toBase64 } from "common/helper/utilities";
import { HomeLayout, Button, Title } from "components";
import { actions } from "../../../../redux/actions/actions";
import { super_Api_Req } from "common/services";

const initialState = {
    form: {},
};

class SuperAdminEditPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        const { history } = this.props;
        if (!history.location.state) return history.push("super-home");

        console.log(history.location.state);
        this.setState({
            promoDetails: history.location.state.data,
        });
    }

    previewImage = (file) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
            this.setState({
                imgSrc: [reader.result],
            });
        };
    };

    onDrop = async (file) => {
        const fileBase64 = await toBase64(file[0]);
        this.previewImage(file);
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                image: fileBase64,
                name: file[0].name,
            },
        }));
    };

    onDeletePromo = (data) => {
        const payload = {
            BannerId: data.bannerId,
        };
        super_Api_Req.put
            .deleteBanner(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    onSubmit = () => {
        const { history } = this.props;
        const { form, promoDetails } = this.state;
        const payload = {
            id: promoDetails.bannerId,
            Image: form.image,
            StartDateUTC: moment(form.date).format("YYYY-MM-DD"),
            EndDateUTC: moment(form.date).add("years", 1).format("YYYY-MM-DD"),
            Points: 1,
        };
        super_Api_Req.put
            .editBanner(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
                history.push("super-home");
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { promoDetails, form, imgSrc } = this.state;

        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-edit-promo-container">
                    <Title title="EDIT PROMOTION" />
                    {promoDetails && (
                        <Fragment>
                            <div className="banner-name">
                                {form.name || promoDetails.image.replace(/^.*[\\/]/, "").split(".")}
                                <Icon icon={trashA} size={18} onClick={() => this.onDeletePromo(promoDetails)} />
                            </div>
                            <div className="preview-container">
                                <div
                                    className="image-container"
                                    style={{
                                        backgroundImage: `url(${imgSrc ? imgSrc : promoDetails.image})`,
                                    }}
                                />
                            </div>
                        </Fragment>
                    )}
                    <Card className="drop-file-zone">
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="drop-file">
                                        <p className="desc">{form.name ? form.name : `DROP IMAGES here`}</p>
                                        <p className="desc">- or -</p>
                                        <input {...getInputProps()} accept="image/*" />
                                        <Button title={form.image ? "REUPLOAD FILE" : "SELECT FILE"} />
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Card>
                    <div className="button-container">
                        <Button title="SAVE" onClick={this.onSubmit} disabled={!form.image} />
                        <Button title="CANCEL" outline onClick={() => this.props.history.push("super-home")} />
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminEditPromotion);
