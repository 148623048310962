import React from "react";
import { Provider } from "react-redux";
import { Store, Presistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
import "./App.css";
import "normalize.css";
import Navigation from "./navigation/navigation";

function App() {
    return (
        <Provider store={Store}>
            <PersistGate loading={null} persistor={Presistor}>
                <Navigation />
            </PersistGate>
        </Provider>
    );
}

export default App;
