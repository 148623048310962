import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/icomoon/search";
import { Label, Input } from "reactstrap";

import { HomeLayout, CardTable } from "components";
import { tHeader } from "../utilities/tableData";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";

class SuperAdminProductDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productLists: [],
        };
    }

    componentDidMount() {
        this.requestProductList();
    }

    requestProductList = () => {
        super_Api_Req.get
            .productLists()
            .then((resp) => {
                const data = [...resp.data];

                data.forEach((o) => {
                    const { sph, cyl, add } = o.leftResult;
                    const { sph: rSph, cyl: rCyl, add: rAdd } = o.rightResult;

                    o.left = `${sph || 0},  ${cyl || 0},  ${add || 0}`;
                    o.right = `${rSph || 0},  ${rCyl || 0},  ${rAdd || 0}`;
                });
                this.setState({
                    productLists: data,
                    productListsData: data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    navigateTo = (path) => {
        const { history } = this.props;
        history.push(path);
    };

    onEdit = (data) => {
        const { history } = this.props;
        history.push("super-prod-details", { data });
    };

    searchProdno = (e) => {
        const value = e.currentTarget.value;
        const { productListsData } = this.state;
        const productLists = productListsData.filter((o) => {
            return o.orderNumber.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        });

        this.setState({ productLists });
    };

    render() {
        const { productLists } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-prod-dashboard-container">
                    <div className="prod-header-container">
                        <h1 className="prod-title">PRODUCT LIST</h1>
                    </div>
                    <div className="search-container">
                        <Label>ORDER NUMBER</Label>
                        <Icon icon={search} className="icon-search" />
                        <Input className="search-input" type="text" placeholder="SEARCH" onChange={this.searchProdno} />
                    </div>
                    <CardTable tableTHeadData={tHeader.productLists} tableTBodyData={productLists} editAppt={this.onEdit} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminProductDashboard);
