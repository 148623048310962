import React, { Component } from "react";
import { connect } from "react-redux";
import { Label, Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Formik } from "formik";

import { HomeLayout, Button } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { ecpAddInputData, addInputValidator } from "../utilities/ecpInputData";

class SuperAdminECPAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inactiveECP: [],
            dropdownOpen: false,
        };
    }

    componentDidMount() {
        this.requestInactiveECP();
    }

    genRandomPassword = () => {
        return Math.random().toString(36).slice(2);
    };

    toggleDD = () => {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    };

    requestInactiveECP = () => {
        super_Api_Req.get
            .ecpInactiveLists()
            .then((resp) => {
                this.setState({
                    inactiveECP: resp.data,
                });
                console.log(resp);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    handleSubmit = (v) => {
        const { history } = this.props;
        console.log(v);
        const payload = {
            StoreId: v.ecpCode.storeId,
            Address: v.address,
            Password: v.password,
            PersonInCharge: v.pic,
            MobileNumber: v.contact,
            Email: v.email,
            Remark: v.remark,
        };
        super_Api_Req.put
            .addECP(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { dropdownOpen, inactiveECP } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-ecp-add-container">
                    <div className="ecp-header-container">
                        <h1 className="ecp-title">ADD ECP</h1>
                    </div>
                    <div className="form-container">
                        <Formik
                            initialValues={{
                                ecpCode: "",
                                address: "",
                                pic: "",
                                contact: "",
                                email: "",
                                remark: "",
                                password: "",
                            }}
                            validationSchema={addInputValidator}
                            onSubmit={(values, { setSubmitting }) => {
                                setSubmitting(false);
                                this.handleSubmit(values);
                            }}
                        >
                            {(props) => {
                                const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, values } = props;
                                return (
                                    <form onSubmit={handleSubmit} className="form-wrapper">
                                        <div className="input-col">
                                            {ecpAddInputData.map((o, i) => {
                                                const error = errors[o.name] && touched[o.name] ? "error" : "";
                                                const inputName = `input-${o.name}`;

                                                if (o.type === "textarea") {
                                                    return (
                                                        <div className="input-container">
                                                            <Label>{o.label}</Label>
                                                            <textarea className={`textarea-input ${inputName} ${error}`} id={o.name} value={values[o.name]} onChange={handleChange} onBlur={handleBlur} rows="4" />
                                                            {errors[o.name] && touched[o.name] && <div className="input-feedback">{errors[o.name]}</div>}
                                                        </div>
                                                    );
                                                } else if (o.type === "dropdown") {
                                                    return (
                                                        <div className="input-container">
                                                            <Label>{o.label}</Label>
                                                            <ButtonDropdown
                                                                isOpen={dropdownOpen}
                                                                toggle={(e) => {
                                                                    this.toggleDD();
                                                                    const data = {
                                                                        ...JSON.parse(e.target.dataset.toggle),
                                                                    };
                                                                    data.value = `${data.ecpCode} ${data.storeName}`;
                                                                    setFieldValue("ecpCode", data);
                                                                }}
                                                            >
                                                                <DropdownToggle caret className={`dropdown-input ${error}`}>
                                                                    {values.ecpCode.value || "SELECT ECP CODE"}
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {inactiveECP.map((d, index) => {
                                                                        return <DropdownItem data-toggle={JSON.stringify(d)} key={`${d.ecpCode}_${index}`}>{`${d.ecpCode} ${d.storeName}`}</DropdownItem>;
                                                                    })}
                                                                </DropdownMenu>
                                                            </ButtonDropdown>
                                                            {errors[o.name] && touched[o.name] && <div className="input-feedback">{errors[o.name]}</div>}
                                                        </div>
                                                    );
                                                } else {
                                                    return (
                                                        <div className="input-container">
                                                            <Label>{o.label}</Label>
                                                            <Input id={o.name} type="text" value={values[o.name]} onChange={handleChange} onBlur={handleBlur} autoComplete="off" disabled={o.disabled} className={`text-input ${inputName} ${error}`} />
                                                            {errors[o.name] && touched[o.name] && <div className="input-feedback">{errors[o.name]}</div>}
                                                        </div>
                                                    );
                                                }
                                            })}
                                            <div className="button-container">
                                                <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                                <Button title="CANCEL" type="button" outline onClick={this.props.history.goBack} />
                                            </div>
                                        </div>
                                        <div className="input-col">
                                            <div className="input-container">
                                                <Label>UPDATE / CHANGE PASSWORD</Label>
                                                <Input id="password" type="text" name="password" value={values.password} autoComplete="off" className={`text-input ${errors.password && touched.password ? "error" : ""}`} disabled />
                                                {errors.password && touched.password && <div className="input-feedback">{errors.password}</div>}
                                            </div>
                                            <Button type="button" title="GENERATE PASSWORD" onClick={() => setFieldValue("password", this.genRandomPassword())} />
                                        </div>
                                    </form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminECPAdd);
