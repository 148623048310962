export const tableHeader = {
    upcomingApptHeader: [
        {
            label: "Order No.",
            key: "orderNumber",
        },
        {
            label: "Patient Name",
            key: "patientName",
        },
        {
            label: "Date and Time",
            key: "appointmentDate",
            format: "dateTime",
        },
        {
            label: "Status",
            key: "status",
        },
        {
            label: "",
            key: "edit",
        },
    ],
    latestNewsHeader: [
        {
            label: "Messages",
            key: "message",
        },
    ],
};
