import { call, put, takeLatest } from "redux-saga/effects";
import { api_Req } from "common/services";
import { actions } from "../actions/actions";

function* fetchUserProfile(action) {
    try {
        const { history } = action.payload;
        const resp = yield call(api_Req.get.profile);
        yield put({ type: actions.SET_USER_PROFILE, data: resp.data });
        history && history.push("/adminhome");
    } catch (err) {
        yield put({ type: actions.ERROR_ALERT, data: err });
    }
}

function* profileSaga() {
    yield takeLatest(actions.FETCH_USER_PROFILE, fetchUserProfile);
}

export default profileSaga;
