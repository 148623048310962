import * as Yup from "yup";

export const addVideoInputValidator = Yup.object().shape({
    title: Yup.string().required("This Field is required."),
    thumbnail: Yup.mixed().required("This Field is required."),
    videoFile: Yup.mixed().required("This Field is required."),
});

export const editVideoInputValidator = Yup.object().shape({
    title: Yup.string().required("This Field is required."),
});

export const addArticleInputValidator = Yup.object().shape(
    {
        title: Yup.string().required("This Field is required."),
        description: Yup.string().required("This Field is required."),
        url: Yup.string().when("pdfFile", {
            is: (pdfFile) => !pdfFile,
            then: Yup.string()
                .required("Either (URL/PDF FILE) Field is required.")
                .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, "This Field required valid url format."),
        }),
        pdfFile: Yup.mixed().when("url", {
            is: (url) => !url,
            then: Yup.mixed().required("Either (URL/PDF FILE) Field is required."),
        }),
    },
    ["url", "pdfFile"]
);

export const editArticleInputValidator = Yup.object().shape({
    title: Yup.string().required("This Field is required."),
    description: Yup.string().required("This Field is required."),
});

export const addResearchInputValidator = Yup.object().shape({
    title: Yup.string().required("This Field is required."),
    pdfFile: Yup.mixed().required("PDF FILE is required."),
});

export const editResearchInputValidator = Yup.object().shape({
    title: Yup.string().required("This Field is required."),
});
