import React, { Component } from "react";
import Icon from "react-icons-kit";
import { FormGroup, Label, Input, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { search } from "react-icons-kit/icomoon/search";

import { HomeLayout, Title, CardTable } from "components";
import { tableNewHeader } from "./tableData/tableData";
import { api_Req } from "common/services";

export default class ClientList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            clientList: [],
            dropdownOpen: false,
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getClientList();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    searchOrderNumber = (e) => {
        const value = e.currentTarget.value;
        const { ClientListData } = this.state;
        const clientList = ClientListData.filter((o) => {
            return o.orderNumber.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        });

        this.setState({
            clientList,
        });
    };

    getClientList = () => {
        api_Req.get
            .clientList()
            .then((resp) => {
                this._isMounted &&
                    this.setState({
                        clientList: resp.data,
                        ClientListData: resp.data,
                    });
            })
            .catch((err) => {
                //err
            });
    };

    viewDetails = (data) => {
        const { history } = this.props;
        history.push("clientDetails", { data });
    };

    toggle = (e) => {
        const { ClientListData } = this.state;
        const value = e.target.dataset.toggle;
        let clientList = [];

        if (value === "Link") {
            clientList = ClientListData.filter((c) => c.linked);
        } else if (value === "Unlink") {
            clientList = ClientListData.filter((c) => !c.linked);
        } else if (value === "All") {
            clientList = ClientListData;
        }

        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
            dropdownVal: value ? value : prevState.dropdownVal,
            clientList: clientList.length ? clientList : prevState.clientList,
        }));
    };

    render() {
        const { clientList, dropdownOpen, dropdownVal } = this.state;
        return (
            <HomeLayout>
                <div className="clientlist_container">
                    <Title title="CLIENT LISTS" />
                    <div className="clientlist_search">
                        <div className="search-wrapper">
                            <FormGroup>
                                <Label>Order Number</Label>
                                <div className="input_container">
                                    <Icon icon={search} className="icon_search" />
                                    <Input className="clientlist_input" type="text" name="email" placeholder="SEARCH" maxLength={20} onChange={this.searchOrderNumber} />
                                </div>
                            </FormGroup>
                            <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
                                <DropdownToggle caret>{dropdownVal || "All"}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem data-toggle="All">All</DropdownItem>
                                    <DropdownItem data-toggle="Link">Link</DropdownItem>
                                    <DropdownItem data-toggle="Unlink">Unlink</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        <div className="clientlist_table">
                            <CardTable type="miyo-points-summary" tableTHeadData={tableNewHeader.clientListHeader} tableTBodyData={clientList} viewDetails={this.viewDetails} />
                        </div>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}
