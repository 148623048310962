import React, { Fragment, PureComponent } from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/img/headLogo.png";
import Footer from "../footer/footer";

export default class LayoutComponent extends PureComponent {
    render() {
        return (
            <Fragment>
                <div className="layout_container">
                    <div className="logo-container">
                        <Link to={this.props.role ? "/super-admin" : "/admin"}>
                            <img className="logo_img" src={logo} alt="hoya-logo" />
                        </Link>
                    </div>
                    {this.props.children}
                </div>
                <Footer />
            </Fragment>
        );
    }
}
