import React, { Component } from "react";
import { connect } from "react-redux";
import { Label, Input, Card, Row, Col } from "reactstrap";
import Icon from "react-icons-kit";
import { trashA } from "react-icons-kit/ionicons/";
import { Formik } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import moment from "moment";

import { toBase64 } from "common/helper/utilities";
import { HomeLayout, Button } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";

export const rewardsValidator = Yup.object().shape({
    itemName: Yup.string().required("This Field is required."),
    points: Yup.string().required("This Field is required."),
    stocklist: Yup.string().required("This Field is required."),
    file: Yup.string().required("This Field is required."),
});

class SuperAdminRewardsEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async componentDidMount() {
        const { history } = this.props;
        if (!history.location.state) return history.push("super-rewards-dashboard");
        const rewards = Object.assign({}, history.location.state.data);
        console.log(rewards);
        this.setState({
            rewardsDetails: rewards,
        });
    }

    previewImage = (file) => {
        if (!file[0]) return;
        const reader = new FileReader();
        reader.onloadend = (e) => {
            this.setState({
                imgSrc: [reader.result],
            });
        };
    };

    handleSubmit = async (v) => {
        const { history } = this.props;
        const { rewardsDetails } = this.state;
        const fileBase64 = await toBase64(v.file[0]);
        const today = new Date();

        const payload = {
            RedemptionId: rewardsDetails.id,
            ItemName: v.itemName,
            ItemDescription: "",
            Points: v.points,
            Quantity: v.stocklist,
            StartDateUTC: moment(today).format("YYYY/MM/DD"),
            EndDateUTC: moment(today).add("years", 1).format("YYYY/MM/DD"),
        };
        if (fileBase64) {
            payload.Image = fileBase64;
        }
        super_Api_Req.put
            .editRewards(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    onDeleteRewards = () => {
        const { rewardsDetails } = this.state;
        const payload = {
            RedemptionId: rewardsDetails.id,
        };
        super_Api_Req.put
            .deleteRewards(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { rewardsDetails, imgSrc } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-rewards-edit-container">
                    <div className="ecp-header-container">
                        <h1 className="ecp-title">EDIT REWARDS</h1>
                    </div>
                    {rewardsDetails && (
                        <div className="form-container">
                            <Formik
                                initialValues={{
                                    itemName: rewardsDetails.itemName,
                                    points: rewardsDetails.points,
                                    stocklist: rewardsDetails.quantity,
                                    file: rewardsDetails.image,
                                }}
                                validationSchema={rewardsValidator}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    this.handleSubmit(values);
                                }}
                            >
                                {(props) => {
                                    const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, setFieldValue, values } = props;
                                    console.log(props);
                                    const errItemName = errors.itemName && touched.itemName ? "error" : "";
                                    const errPoints = errors.points && touched.points ? "error" : "";
                                    const errStocklist = errors.stocklist && touched.stocklist ? "error" : "";
                                    const errFile = errors.file && touched.file ? "error" : "";
                                    const file = values.file ? values.file[0].name : "";
                                    return (
                                        <form onSubmit={handleSubmit} className="form-wrapper">
                                            <div className="input-col">
                                                <div className="input-container">
                                                    <Label>ITEM NAME*</Label>
                                                    <Input className={`text-input ${errItemName}`} id="itemName" value={values.itemName} onChange={handleChange} onBlur={handleBlur} />
                                                    {errors.itemName && touched.itemName && <div className="input-feedback">{errors.itemName}</div>}
                                                </div>
                                                <div className="input-container">
                                                    <Label>POINTS(PT)*</Label>
                                                    <Input className={`text-input ${errPoints}`} id="points" value={values.points} onChange={handleChange} onBlur={handleBlur} />
                                                    {errors.points && touched.points && <div className="input-feedback">{errors.points}</div>}
                                                </div>
                                                <div className="input-container">
                                                    <Label>STOCKLIST*</Label>
                                                    <Input className={`text-input ${errStocklist}`} id="stocklist" value={values.stocklist} onChange={handleChange} onBlur={handleBlur} />
                                                    {errors.stocklist && touched.stocklist && <div className="input-feedback">{errors.stocklist}</div>}
                                                </div>
                                                <Card className={`drop-file-zone ${errFile}`}>
                                                    <Dropzone
                                                        onDrop={(file) => {
                                                            setFieldValue("file", file);
                                                            this.previewImage(file);
                                                        }}
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <section>
                                                                <div {...getRootProps()} className="drop-file">
                                                                    <p className="desc">{file || `DROP IMAGES here`}</p>
                                                                    <p className="desc">- or -</p>
                                                                    <input {...getInputProps()} accept="image/*" />
                                                                    <Button type="button" title={file ? "REUPLOAD FILE" : "SELECT FILE"} />
                                                                </div>
                                                            </section>
                                                        )}
                                                    </Dropzone>
                                                </Card>

                                                {errors.file && touched.file && <div className="input-feedback">{errors.file}</div>}
                                                {values.itemName && values.points && values.stocklist && values.file && (
                                                    <Card className="rewards-preview-container">
                                                        <Row>
                                                            <Col>
                                                                <div
                                                                    className="image-container"
                                                                    style={{
                                                                        backgroundImage: `url(${file ? `${imgSrc}` : values.file})`,
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <p>{values.itemName}</p>
                                                            </Col>
                                                            <Col>
                                                                <Icon icon={trashA} onClick={this.onDeleteRewards} size={23} />
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                )}

                                                <div className="button-container">
                                                    <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                                    <Button title="CANCEL" type="button" outline onClick={this.props.history.goBack} />
                                                </div>
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    )}
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminRewardsEdit);
