import * as Yup from "yup";

export const inputField = [
    {
        label: "Contact Number",
        name: "mobileNumber",
        key: "MobileNumber",
        placeholder: "012123456789",
    },
    {
        label: "Person In Charge",
        name: "personInCharge",
        key: "PersonInCharge",
        // placeholder: "012123456789"
    },
    {
        label: "Fax",
        name: "fax",
        key: "Fax",
        placeholder: "03212345679",
    },
    {
        label: "Email",
        name: "email",
        key: "Email",
        placeholder: "example@gmail.com",
    },
    {
        label: "Website",
        name: "website",
        key: "Website",
        placeholder: "www.example.com",
    },
    {
        label: "Business Hour",
        key: "BusinessHour",
        name: "businessHour",
        specialInput: [
            {
                label: "Mon",
                name: "mon",
            },
            {
                label: "Tues",
                name: "tues",
            },
            {
                label: "Wed",
                name: "wed",
            },
            {
                label: "Thurs",
                name: "thurs",
            },
            {
                label: "Fri",
                name: "fri",
            },
            {
                label: "Sat",
                name: "sat",
            },
            {
                label: "Sun",
                name: "sun",
            },
        ],
    },
    {
        label: "Address",
        key: "Address",
        name: "address",
    },
    {
        label: "Country",
        key: "Country",
        name: "country",
    },
    {
        label: "Password",
        key: "Password",
        name: "password",
        specialInput: [
            {
                label: "Password",
                name: "Password",
                placeholder: "Password",
            },
            {
                label: "Confirm Password",
                name: "ConfirmPassword",
                placeholder: "Confirm Password",
            },
        ],
    },
];

const phoneRegExp = /^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/;
export const inputValidator = {
    password: Yup.object().shape({
        Password: Yup.string().required("Required").min(6, "Minimum 6 character password").max(20, "Try a shorter password."),
        ConfirmPassword: Yup.string()
            .required("Required")
            .min(6, "Minimum 6 character password")
            .max(20, "Try a shorter password.")
            .test("passwords-match", "Passwords must match", function (value) {
                return this.parent.Password === value;
            }),
    }),
    email: Yup.object().shape({
        Email: Yup.string().email().required("Required"),
    }),
    pic: Yup.object().shape({
        Pic: Yup.string().required("Required"),
    }),
    country: Yup.object().shape({ Country: Yup.string().required("Required") }),
    name: Yup.object().shape({ Name: Yup.string().required("Required") }),
    website: Yup.object().shape({ Website: Yup.string().required("Required") }),
    fax: Yup.object().shape({
        Fax: Yup.number().typeError("Fax number is not valid").required("Required"),
    }),
    mobileNumber: Yup.object().shape({
        MobileNumber: Yup.string().required("Required").matches(phoneRegExp, "Phone number is not valid"),
    }),
    businessHour: {},
};
