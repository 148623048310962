import React, { Component, Fragment } from "react";
import { Label, Input } from "reactstrap";
import Icon from "react-icons-kit";
import { search } from "react-icons-kit/icomoon/search";

import { HomeLayout, Title } from "components";
import ClientCard from "components/admin/clientCard/clientCard";
import { api_Req } from "common/services";

export default class ClientDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientDetails: {},
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state) {
            this.getClientDetails(history.location.state.data.orderNumber);
            this.setState({
                orderNumber: history.location.state.data.orderNumber,
            });
        } else {
            history.push("clientlist");
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { location } = this.props;
        console.log(location.state.data, prevState.orderNumber);
        if (prevState.orderNumber !== location.state.data.orderNumber) {
            this.getClientDetails(location.state.data.orderNumber);
            this.setState({
                orderNumber: location.state.data.orderNumber,
            });
        }
    }

    getClientDetails = (orderNumber) => {
        api_Req.get
            .clientDetails(orderNumber)
            .then((resp) => {
                this.setState({
                    clientDetails: resp.data,
                });
            })
            .catch((err) => {
                //err
            });
    };

    render() {
        const { clientDetails } = this.state;
        return (
            <HomeLayout>
                <div className="clientdetails_container">
                    <Title title="CLIENT DETAILS" />
                    {Object.keys(clientDetails).length ? (
                        <Fragment>
                            <div className="ordernum_container">
                                <Label>ORDER NUMBER</Label>
                                <div className="input-wrapper">
                                    <Icon icon={search} className="icon_search" />
                                    <Input className="ordernum_input" type="text" name="ordernum" placeholder="SEARCH" maxLength={20} value={`${clientDetails.orderNumber} - ${clientDetails.profileInfo.profileName}`} disabled />
                                </div>
                            </div>
                            <div>
                                <ClientCard clientDetails={clientDetails} />
                            </div>
                        </Fragment>
                    ) : (
                        <p>Waiting user to register</p>
                    )}
                </div>
            </HomeLayout>
        );
    }
}
