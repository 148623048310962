import React, { useState, useMemo } from "react";
import { Label } from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import Icon from "react-icons-kit";
import { connect } from "react-redux";
import { iosCheckmarkOutline } from "react-icons-kit/ionicons/iosCheckmarkOutline";

import { HomeLayout, Title, Button, PopUp } from "components";
import { actions } from "../../../../redux/actions/actions";
import { api_Req } from "common/services";

const WarrantyRequest = (props) => {
    const expired = props?.location?.state?.apptData?.expired;
    const warrantyAnswers = props?.location?.state?.apptData?.warrantyResponse || "";
    const initialValues = useMemo(
        () => ({
            Question1: warrantyAnswers.question1,
            Question2: warrantyAnswers.question2,
            Question3: warrantyAnswers.question3,
            Question4: warrantyAnswers.question4,
            terms: false,
            agreeTnc: false,
        }),
        [warrantyAnswers]
    );
    const [successPopupVisible, setSuccessPopupVisible] = useState(false);

    const onHandleToggleSuccessPopupVisible = () => {
        setSuccessPopupVisible(!successPopupVisible);
    };

    const onSubmit = async (values) => {
        const apptData = props.location.state.apptData;

        try {
            const payload = {
                Id: apptData.appointmentId,
                OrderNumber: apptData.orderNumber,
                ...values,
            };
            const response = await api_Req.post.setApptWarranty(payload);
            console.log(response);
            onHandleToggleSuccessPopupVisible();
        } catch (err) {
            props.alertError(err);
        }
    };

    const goBack = () => {
        const apptData = props.location.state.apptData;
        props.history.push("editappointment", { apptData });
    };

    return (
        <HomeLayout>
            <div className="warranty-request">
                <Title title="WARRANTY REQUEST" />

                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        Question1: Yup.string().required("This field is required"),
                        Question2: Yup.string().required("This field is required"),
                        Question3: Yup.string().required("This field is required"),
                        Question4: Yup.string().required("This field is required"),
                        terms: Yup.boolean().oneOf([true], "You must accept the terms of use"),
                        agreeTnc: Yup.boolean().oneOf([true], "You must accept the statements above"),
                    })}
                    onSubmit={(values) => {
                        onSubmit(values);
                    }}>
                    {(props) => {
                        const { values, errors, isSubmitting, handleChange, handleSubmit } = props;

                        return (
                            <form autoComplete="off" onSubmit={handleSubmit}>
                                <div className="warranty-request__input">
                                    <Label>1. Patient’s age</Label>
                                    <textarea name="Question1" onChange={handleChange} defaultValue={values.Question1} disabled={expired} />
                                    <p className="warranty-request__error">{errors.age}</p>
                                </div>
                                <div className="warranty-request__input">
                                    <Label>2. History of previous myopia control treatment</Label>
                                    <textarea name="Question2" onChange={handleChange} defaultValue={values.Question2} disabled={expired} />
                                    <p className="warranty-request__error">{errors.history}</p>
                                </div>
                                <div className="warranty-request__input">
                                    <Label>3. Annual progression rate before MiYOSMART</Label>
                                    <textarea name="Question3" onChange={handleChange} defaultValue={values.Question3} disabled={expired} />
                                    <p className="warranty-request__error">{errors.rate}</p>
                                </div>
                                <div className="warranty-request__input">
                                    <Label>4. Axial length before and after MiYOSMART (please insert both if available)</Label>
                                    <textarea name="Question4" onChange={handleChange} defaultValue={values.Question4} disabled={expired} />
                                    <p className="warranty-request__error">{errors.axial}</p>
                                </div>
                                <div className="warranty-request__checkbox">
                                    <Label>
                                        <input name="terms" type="checkbox" onChange={handleChange} defaultValue={values.terms} /> I agree to the{" "}
                                        <a href="terms_of_use_and_privacy_v2.pdf" target="_blank">
                                            Terms of use
                                        </a>
                                        <p className="warranty-request__error">{errors.terms}</p>
                                    </Label>
                                </div>
                                <div className="warranty-request__checkbox">
                                    <Label>
                                        <input name="agreeTnc" type="checkbox" onChange={handleChange} defaultValue={values.agreeTnc} /> Based on my observation and checking, I confirm that the above statements are true.
                                        <p className="warranty-request__error">{errors.agreeTnc}</p>
                                    </Label>
                                </div>
                                <div className="warranty-request__button-container">
                                    <Button title="Back" type="button" outline onClick={goBack} />
                                    <Button title="Submit" type="submit" disabled={expired || isSubmitting} />
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </div>

            <PopUp visible={successPopupVisible} toggle={onHandleToggleSuccessPopupVisible} className="warranty-request-pop-up">
                <div className="warranty-request-pop-up__container">
                    <Icon icon={iosCheckmarkOutline} size={100} />
                    <Title title="YOUR REQUEST HAS BEEN SUBMITTED" />
                    <p className="warranty-request-pop-up__text">After HOYA review your request, HOYA representative will reach out to you within 3 business days.</p>
                </div>
            </PopUp>
        </HomeLayout>
    );
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(null, mapDispatchToProps)(WarrantyRequest);
