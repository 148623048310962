import LayoutComponent from "./public/layout/layout";
import ButtonComponent from "./public/button/button";
import FooterComponent from "./public/footer/footer";
import LoginCardComponent from "./public/loginCard/loginCard";
import TitleComponent from "./public/title/title";
import HomeLayoutComponent from "./public/homeLayout/homeLayout";
import TopNavComponent from "./public/topNav/topNav";
import CardTableComponent from "./public/CardTable/CardTable";
import CalendarComponent from "./admin/calendar/calendar";
import AlertComponent from "./public/alert/alert";
import PopUpComponent from "./public/popUp/popUp";
import PromoCardComponent from "./super-admin/promoCard/promoCard";
/* ADMIN */
const Calendar = CalendarComponent;

/* PUBLIC */
const Layout = LayoutComponent;
const Button = ButtonComponent;
const Footer = FooterComponent;
const LoginCard = LoginCardComponent;
const Title = TitleComponent;
const HomeLayout = HomeLayoutComponent;
const TopNav = TopNavComponent;
const CardTable = CardTableComponent;
const Alert = AlertComponent;
const PopUp = PopUpComponent;
const PromoCard = PromoCardComponent;

export { Layout, Button, Footer, LoginCard, Title, HomeLayout, TopNav, CardTable, Calendar, Alert, PopUp, PromoCard };
