import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

export default class PopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { children, visible, toggle, className } = this.props;
        return (
            <Modal isOpen={visible} className={`miyosmart-pop-up-container ${className}`} contentClassName="miyosmart-pop-up-content" toggle={toggle}>
                <div className="miyosmart-pop-up-container__wrapper">
                    <div className="miyosmart-pop-up-container__outer-layer" />
                    <div className="miyosmart-pop-up-container__body">
                        <ModalBody>{children}</ModalBody>
                    </div>
                </div>
            </Modal>
        );
    }
}
