import React, { Component } from "react";
import { Card } from "reactstrap";

class LoginCardComponent extends Component {
    render() {
        return (
            <div className="login-card">
                <div className="login-card__outer-layer" />
                <div className="login-card__miyobee" />
                <Card className="login-card__container">
                    <div className="login-card__content">{this.props.children}</div>
                </Card>
            </div>
        );
    }
}

export default LoginCardComponent;
