import React, { Component } from "react";
import { connect } from "react-redux";

import { api_Req } from "common/services";
import { HomeLayout, Title } from "components";
import { actions } from "../../redux/actions/actions";
import CorporateCard from "components/admin/corpCard/corpCard";

class CorporateInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    onHandleSubmit = (data) => {
        let payload = null;
        if (data.BusinessHour) {
            payload = data;
        } else {
            payload = { ...data };
        }
        this.updateProfile(payload);
    };

    updateProfile = (payload) => {
        api_Req.put
            .updateProfile(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchUserProfile();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { userProfile } = this.props;
        return (
            <HomeLayout>
                <div className="corporate_container">
                    <Title title="Corporate Info" />
                    <CorporateCard userProfile={userProfile.profile} onSave={this.onHandleSubmit} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
        userProfile: state.userProfile,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
    fetchUserProfile: (history) => dispatch({ type: actions.FETCH_USER_PROFILE, payload: { history } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CorporateInfo);
