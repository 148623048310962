import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import Icon from "react-icons-kit";
import { chevronLeft } from "react-icons-kit/ionicons/";
import Dropzone from "react-dropzone";
import { tHeader } from "./utilities/tableData";

import { HomeLayout, Button, Title, CardTable } from "components";
import { actions } from "../../../redux/actions/actions";
import { super_Api_Req } from "common/services";

const initialState = {
    form: {},
};

class SuperAdminAddCsvFile extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onDrop = (file) => {
        if (file) {
            if (file.length !== 0) {
                this.setState((prevState) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        name: file[0].name,
                        file: file[0],
                    },
                }));
            }
        }
    };

    onSubmit = () => {
        const { form } = this.state;
        let formData = new FormData();
        formData.append("File", form.file);

        super_Api_Req.post
            .addCsv(formData)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
                this.setState(initialState);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { form } = this.state;
        const {
            superSummary: { csv },
        } = this.props;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-add-csv-container">
                    <div className="title-container">
                        <Title title="UPLOAD CSV FILE" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>

                    <Card className="drop-file-zone">
                        <Dropzone accept=".csv" onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="drop-file">
                                        <p className="desc">{form.name ? form.name : `DROP CSV FILE HERE`}</p>
                                        <p className="desc">- or -</p>
                                        <input {...getInputProps()} accept=".csv" />
                                        <Button title={form.file ? "REUPLOAD FILE" : "SELECT FILE"} />
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Card>
                    <CardTable tableTHeadData={tHeader.csvFileList} tableTBodyData={csv} />
                    <div className="button-container">
                        <Button title="SAVE" onClick={this.onSubmit} disabled={!form.file} />
                        <Button title="CANCEL" outline onClick={() => this.props.history.push("super-home")} />
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminAddCsvFile);
