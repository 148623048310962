import React, { Component } from "react";
import { connect } from "react-redux";
import Icon from "react-icons-kit";
import { chevronLeft, trashA } from "react-icons-kit/ionicons/";
import { Label, Input } from "reactstrap";
import { Formik } from "formik";

import { HomeLayout, Button, Title } from "components";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";
import { editResearchInputValidator } from "../utilities/mediaInputData";

import MediaDeletePopup from "../mediaDeletePopup/mediaDeletePopup";

class SuperAdminMediaEditResearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            removeModalStatus: false,
            articleDetails: {},
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (!history.location.state) return history.push("super-media-dashboard");
        this.getArticleDetails(history.location.state.id);
    }

    getArticleDetails = (id) => {
        super_Api_Req.get
            .articleDetails(id)
            .then((resp) => {
                console.log(resp);
                this.setState({
                    articleDetails: resp.data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    onSubmit = (values) => {
        const { articleDetails } = this.state;

        const formData = new FormData();
        formData.append("id", articleDetails.id);
        formData.append("articleType", articleDetails.articleType);

        if (values.remove) {
            formData.append("active", false);
        } else {
            formData.append("title", values.title);
            formData.append("active", true);
        }

        super_Api_Req.post
            .editArticle(formData)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    modalToggle = () => {
        this.setState({
            ...this.state,
            removeModalStatus: !this.state.removeModalStatus,
        });
    };

    handleRemove = () => {
        this.onSubmit({ remove: true });
        this.modalToggle();
    };

    render() {
        const { articleDetails, removeModalStatus } = this.state;

        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-edit-research-container">
                    <div className="title-container">
                        <Title title="EDIT RESEARCH PAPER" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>
                    {Object.keys(articleDetails).length ? (
                        <div className="form-container">
                            <Formik
                                initialValues={{
                                    title: articleDetails.title,
                                    content: articleDetails.content,
                                }}
                                validationSchema={editResearchInputValidator}
                                onSubmit={(values, { setSubmitting }) => {
                                    setSubmitting(false);
                                    this.onSubmit(values);
                                }}
                            >
                                {(props) => {
                                    const { touched, errors, isSubmitting, handleBlur, handleChange, handleSubmit, values } = props;
                                    return (
                                        <form onSubmit={handleSubmit} className="form-wrapper">
                                            <div className="input-container">
                                                <Label>TITLE</Label>
                                                <Input
                                                    id="title"
                                                    name="title"
                                                    type="text"
                                                    value={values.title}
                                                    className={`
                          text-input
                          ${errors.title && touched.title ? "error" : ""}
                        `}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    autoComplete="off"
                                                />
                                                {errors.title && touched.title && <div className="input-feedback">{errors.title}</div>}
                                            </div>
                                            <div className="input-container">
                                                <div className="pdf-container">
                                                    <iframe title="My PDF File" type="application/pdf" src={values.content + "#zoom=FitH"} width="100%" height="100%" />
                                                </div>
                                            </div>
                                            <div className="remove-card">
                                                <div className="card-title">{values.title}</div>
                                                <div className="card-icon">
                                                    <Icon icon={trashA} type="button" onClick={this.modalToggle} size={23} />
                                                </div>
                                            </div>
                                            <div className="button-container">
                                                <Button type="submit" title="SAVE" disabled={isSubmitting} />
                                                <Button type="button" title="CANCEL" outline onClick={() => this.props.history.push("super-media-dashboard")} />
                                            </div>
                                        </form>
                                    );
                                }}
                            </Formik>
                        </div>
                    ) : null}
                    <MediaDeletePopup removeModalStatus={removeModalStatus} modalToggle={this.modalToggle} deleteArticle={this.handleRemove} />
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminMediaEditResearch);
