import SuperAdminLogin from "./login/login";
import SuperAdminHome from "./home/home";
import SuperAdminAddNews from "./addNewsLists/addNewsList";
import SuperAdminAddPromotion from "./promotionLists/addPromotion/addPromotion";
import SuperAdminEditPromotion from "./promotionLists/editPromotion/editPromotion";
import SuperAdminECPDashboard from "./ecpManagement/ecpDashboard/ecpDashboard";
import SuperAdminECPEdit from "./ecpManagement/ecpEdit/ecpEdit";
import SuperAdminECPAdd from "./ecpManagement/ecpAdd/ecpAdd";
import SuperAdminRewardsDashboard from "./rewardsManagement/rewardsDashboard/rewardsDashboard";
import SuperAdminRewardsAdd from "./rewardsManagement/rewardsAdd/rewardsAdd";
import SuperAdminRewardsEdit from "./rewardsManagement/rewardsEdit/rewardsEdit";
import SuperAdminProductDashboard from "./productLists/productDashboard/productDashboard";
import SuperAdminProductDetails from "./productLists/productDetails/productDetails";
import SuperAdminRedemptionsDashboard from "./redemptions/redemptionsDashboard/redemptionsDashboard";
import SuperAdminRedemptionsDetails from "./redemptions/redemptionsDetails/redemptionsDetails";
import SuperAdminAddCsvFile from "./addCsvFile/addCsvFile";
import SuperAdminMediaDashboard from "./mediaManagement/mediaDashboard/mediaDashboard";
import SuperAdminMediaAddVideo from "./mediaManagement/mediaAddVideo/mediaAddVideo";
import SuperAdminMediaEditVideo from "./mediaManagement/mediaEditVideo/mediaEditVideo";
import SuperAdminMediaAddArticle from "./mediaManagement/mediaAddArticle/mediaAddArticle";
import SuperAdminMediaEditArticle from "./mediaManagement/mediaEditArticle/mediaEditArticle";
import SuperAdminMediaAddResearch from "./mediaManagement/mediaAddResearch/mediaAddResearch";
import SuperAdminMediaEditResearch from "./mediaManagement/mediaEditResearch/mediaEditResearch";

const SuperLogin = SuperAdminLogin;
const SuperHome = SuperAdminHome;
const SuperAddNews = SuperAdminAddNews;
const SuperAddPromotion = SuperAdminAddPromotion;
const SuperEditPromotion = SuperAdminEditPromotion;
const SuperECPDashboard = SuperAdminECPDashboard;
const SuperECPEdit = SuperAdminECPEdit;
const SuperECPAdd = SuperAdminECPAdd;
const SuperRewardsDashboard = SuperAdminRewardsDashboard;
const SuperRewardsAdd = SuperAdminRewardsAdd;
const SuperRewardsEdit = SuperAdminRewardsEdit;
const SuperProductDashboard = SuperAdminProductDashboard;
const SuperProductDetails = SuperAdminProductDetails;
const SuperRedemptionsDashboard = SuperAdminRedemptionsDashboard;
const SuperRedemptionsDetails = SuperAdminRedemptionsDetails;
const SuperAddCsvFile = SuperAdminAddCsvFile;
const SuperMediaDashboard = SuperAdminMediaDashboard;
const SuperMediaAddVideo = SuperAdminMediaAddVideo;
const SuperMediaEditVideo = SuperAdminMediaEditVideo;
const SuperMediaAddArticle = SuperAdminMediaAddArticle;
const SuperMediaEditArticle = SuperAdminMediaEditArticle;
const SuperMediaAddResearch = SuperAdminMediaAddResearch;
const SuperMediaEditResearch = SuperAdminMediaEditResearch;

export {
    SuperLogin,
    SuperHome,
    SuperAddNews,
    SuperAddPromotion,
    SuperEditPromotion,
    SuperECPDashboard,
    SuperECPEdit,
    SuperECPAdd,
    SuperRewardsDashboard,
    SuperRewardsAdd,
    SuperRewardsEdit,
    SuperProductDashboard,
    SuperProductDetails,
    SuperRedemptionsDashboard,
    SuperRedemptionsDetails,
    SuperAddCsvFile,
    SuperMediaDashboard,
    SuperMediaAddVideo,
    SuperMediaEditVideo,
    SuperMediaAddArticle,
    SuperMediaEditArticle,
    SuperMediaAddResearch,
    SuperMediaEditResearch,
};
