import React, { Component } from "react";
import { connect } from "react-redux";

import { HomeLayout, CardTable } from "components";
import { tableHeader } from "./tableData/tableData";
import { api_Req } from "common/services";
import { actions } from "../../../redux/actions/actions";

class AdminHome extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            appointmentList: [],
            latestNews: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.getUpcomingAppts();
        this.getLastesNews();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getUpcomingAppts = () => {
        api_Req.get
            .upcomingAppts()
            .then((resp) => {
                this._isMounted &&
                    this.setState({
                        appointmentList: resp.data.appointmentList,
                    });
            })
            .catch((err) => {
                //err
            });
    };

    getLastesNews = () => {
        api_Req.get
            .latestNews()
            .then((resp) => {
                this._isMounted &&
                    this.setState({
                        latestNews: resp.data,
                    });
            })
            .catch((err) => {
                //err
            });
    };

    onEdit = (data) => {
        const { history } = this.props;
        history.push("editappointment", { apptData: data });
        console.log(data);
    };

    render() {
        const { appointmentList, latestNews } = this.state;
        return (
            <HomeLayout>
                <div className="adminhome_container">
                    <CardTable title="LATEST NEWS" tableTHeadData={tableHeader.latestNewsHeader} tableTBodyData={latestNews} />
                    <div className="adminhome_apptable">
                        <CardTable title="UPCOMING APPOINTMENTS" tableTHeadData={tableHeader.upcomingApptHeader} tableTBodyData={appointmentList} editAppt={this.onEdit} />
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alert: state.alert,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    fetchUserProfile: () => dispatch({ type: actions.FETCH_USER_PROFILE }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome);
