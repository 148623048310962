import React, { Component } from "react";
import Icon from "react-icons-kit";
import { FormGroup, Label, Input } from "reactstrap";
import { search } from "react-icons-kit/icomoon/search";
import { ic_expand_more } from "react-icons-kit/md/ic_expand_more";
import { ic_chevron_left, ic_chevron_right } from "react-icons-kit/md/";
import { Calendar } from "react-calendar";
import moment from "moment";
import { connect } from "react-redux";

import { HomeLayout, Title, Button } from "components";
import { api_Req, timeSlotData } from "common/services";
import { actions } from "../../../../redux/actions/actions";

class CreateAppointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            calendarVisible: false,
            calendarOverlay: false,
            showOrderList: false,
            orderList: [],
            orderNumber: "",
            searchValue: "",
            selectedTimeSlot: {
                label: "",
            },
            profile: {},
        };
    }

    componentDidMount() {
        const { location, history } = this.props;
        if (!location.state) return history.push("appointmentcalendar");
        const apptDate = location.state.appointmentDate;
        this.setState({
            date: apptDate,
        });

        this.requestOrderList();
        this.requestApptTimeSlot(moment(apptDate).format("YYYY-MM-DD"));
    }

    requestOrderList = () => {
        api_Req.get
            .apptOrderList()
            .then((resp) => {
                this.setState({
                    orderList: resp.data.orderList,
                    orderListData: resp.data.orderList,
                });
            })
            .catch((err) => {
                //err
                this.props.alertError(err);
            });
    };

    requestApptTimeSlot = (date) => {
        const { history } = this.props;
        const payload = {
            Date: date,
        };
        api_Req.post
            .apptTimeSlot(payload)
            .then((resp) => {
                this.setState({
                    timeSlot: resp.data.slotsAvailable,
                });
            })
            .catch((err) => {
                history.push("appointmentcalendar");
                this.props.alertError(err);
            });
    };

    handleSubmit = () => {
        const { history } = this.props;
        const { date, selectedTimeSlot, orderData, profile } = this.state;

        if (!orderData) return;
        if (!selectedTimeSlot.index && selectedTimeSlot.index !== 0) {
            this.props.alertError({ message: "Please Select Time Slot" });
            return;
        }
        const payload = {
            OrderNumber: orderData.orderNumber,
            MobileNumber: profile.mobileNumber || "",
            AppointmentAtUTC: moment(date).format("YYYY-MM-DD"),
            Slot: selectedTimeSlot.index + 1,
            ProfileName: orderData.ownerName || profile.profileName || "",
            DOB: moment(date).format("YYYY-MM-DD"),
        };
        api_Req.post
            .setAppt(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                history.goBack();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    toggleDate = () => {
        this.setState((prevState) => ({
            calendarVisible: !prevState.calendarVisible,
            calendarOverlay: !prevState.calendarOverlay,
        }));
    };

    handleDateChange = (date) => {
        this.requestApptTimeSlot(moment(date).format("YYYY-MM-DD"));
        this.setState({ date });
    };

    setTimeSlot = (data) => {
        this.setState({
            selectedTimeSlot: data,
        });
    };

    handleProfile = (e) => {
        const value = e.currentTarget.value;
        const name = e.currentTarget.name;
        this.setState((prevState) => ({
            ...prevState,
            profile: {
                ...prevState.profile,
                [name]: value,
            },
        }));
    };

    searchOrderNumber = (e) => {
        const value = e.currentTarget.value;
        const { orderListData } = this.state;
        const orderList =
            orderListData &&
            orderListData.filter((o) => {
                return o.orderNumber.toLowerCase().indexOf(value.toLowerCase()) >= 0;
            });

        this.setState({
            orderNumber: "",
            searchValue: value,
            orderList,
        });
    };

    onHandleOrderListVisible = () => {
        this.setState((prevState) => ({
            showOrderList: !prevState.showOrderList,
        }));
    };

    onHandleOrderNumber = (data) => {
        this.setState((prevState) => ({
            searchValue: "",
            profile: {},
            orderData: data,
            orderNumber: `${data.orderNumber} ${data.ownerName}`,
            showOrderList: !prevState.showOrderList,
        }));
    };

    render() {
        const { calendarOverlay, calendarVisible, date, showOrderList, orderList, orderNumber, searchValue, timeSlot, orderData, selectedTimeSlot } = this.state;
        return (
            <HomeLayout>
                {calendarOverlay && <div id="overlay" onClick={this.toggleDate} />}
                {calendarVisible ? (
                    <div className="create-appt-calendar">
                        <div className="calendar-wrapper">
                            <div className="appt-calendar">
                                <Calendar
                                    locale="US"
                                    minDate={new Date()}
                                    onChange={this.handleDateChange}
                                    value={date}
                                    prevLabel={<Icon icon={ic_chevron_left} size={20} className="calendar_button" />}
                                    nextLabel={<Icon icon={ic_chevron_right} size={20} className="calendar_button" />}
                                    prev2Label={null}
                                    next2Label={null}
                                />
                                <div className="timeselect_container">
                                    <div className="timeselect_title">
                                        <Title title="TIME SLOT" />
                                        {timeSlotData.map((data, i) => {
                                            const selectedTime = selectedTimeSlot && selectedTimeSlot.index === i ? "active" : "";
                                            return (
                                                <button key={`${data.index}_${i}_time_slot`} disabled={!timeSlot[i]} className={selectedTime} onClick={() => this.setTimeSlot(data)}>
                                                    {data.label}
                                                </button>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
                <div className="createappt_container">
                    <Title title="CREATE APPOINTMENT" />
                    <div className="createappt_search">
                        <FormGroup>
                            <Label>Order Number</Label>
                            <div className="ordernum_container">
                                <Icon icon={search} className="icon_search" />
                                <Input
                                    className="ordernum_input"
                                    type="text"
                                    name="ordernum"
                                    placeholder="SEARCH"
                                    maxLength={20}
                                    autoComplete="off"
                                    onChange={this.searchOrderNumber}
                                    onFocus={this.onHandleOrderListVisible}
                                    value={searchValue || orderNumber}
                                />
                                {showOrderList && orderList && (
                                    <ul className="order-number-list">
                                        {orderList.map((data, i) => {
                                            return (
                                                <li className="order-number-item" key={`${i}_${data.orderNumber}_${data.ownerName}`} onClick={() => this.onHandleOrderNumber(data)}>
                                                    {data.orderNumber} {data.ownerName}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </div>
                            {orderData && !orderData.profileExist && (
                                <div className="profile_input_container">
                                    <Label>Profile Name</Label>
                                    <Input type="text" name="profileName" placeholder="eg: bob" onChange={this.handleProfile} maxLength={20} autoComplete="off" />
                                    <Label>Mobile Number</Label>
                                    <Input type="text" name="mobileNumber" placeholder="eg: 0123456789" onChange={this.handleProfile} maxLength={20} autoComplete="off" />
                                    <Label>Date Of Birth</Label>
                                    <Input type="text" name="dateOfBirth" placeholder="eg: DD-MM-YYYY" onChange={this.handleProfile} maxLength={20} autoComplete="off" />
                                </div>
                            )}
                            <div className="datetime_container">
                                <Label>Select Date and Time</Label>
                                <div className="date-input-wrapper" onClick={this.toggleDate}>
                                    <Icon icon={ic_expand_more} className="icon_chevron" size={25} />
                                    <Input className="datetime_input" value={`${moment(date).format("DD/MM/YYYY")}  ${selectedTimeSlot.label || "Select Time Slot"}`} maxLength={20} readOnly />
                                </div>
                            </div>
                            <div className="button_container">
                                <Button title="SAVE" onClick={this.handleSubmit} />
                                <Button title="CANCEL" outline onClick={this.props.history.goBack} />
                            </div>
                        </FormGroup>
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(null, mapDispatchToProps)(CreateAppointment);
