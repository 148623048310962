import React, { Component, Fragment } from "react";
import { Label, Input } from "reactstrap";
import Icon from "react-icons-kit";
import { connect } from "react-redux";
import { chevronLeft } from "react-icons-kit/ionicons/";
import { HomeLayout, Title } from "components";
import ClientCard from "components/super-admin/clientCard/clientCard";
import { super_Api_Req } from "common/services";
import { actions } from "../../../../redux/actions/actions";

class SuperAdminProductDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prodDetails: {},
        };
    }

    componentDidMount() {
        const { history } = this.props;
        if (history.location.state) {
            this.requestProductDetails(history.location.state.data);
        } else {
            history.push("super-prod-dashboard");
        }
    }

    requestProductDetails = (data) => {
        const { history } = this.props;
        super_Api_Req.get
            .productDetails(data.id)
            .then((resp) => {
                this.setState({
                    prodDetails: resp.data,
                });
            })
            .catch((err) => {
                this.props.alertError(err);
                history.push("super-prod-dashboard");
            });
    };

    requestUpdatePoints = (payload) => {
        const { history } = this.props;
        super_Api_Req.put
            .updatePoints(payload)
            .then((resp) => {
                this.requestProductDetails(history.location.state.data);
                this.props.alertSuccess(resp.data.message);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    render() {
        const { prodDetails } = this.state;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-prod-details-container">
                    <div className="prod_container">
                        <div className="title-container">
                            <Title title="PRODUCT DETAILS" />
                            <div className="back-btn-container" onClick={this.props.history.goBack}>
                                <Icon icon={chevronLeft} size={10} />
                                <p>BACK</p>
                            </div>
                        </div>
                        {Object.keys(prodDetails).length ? (
                            <Fragment>
                                <div className="ordernum_container">
                                    <Label>ORDER NUMBER</Label>
                                    <Input className="ordernum_input" type="text" name="ordernum" placeholder="SEARCH" maxLength={20} value={`${prodDetails.orderNumber} - ${prodDetails.ecpName} `} disabled />
                                </div>
                                <div>
                                    <ClientCard prodDetails={prodDetails} requestUpdatePoints={this.requestUpdatePoints} />
                                </div>
                            </Fragment>
                        ) : null}
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => {
        dispatch({ type: actions.ERROR_ALERT, data });
    },
    alertSuccess: (data) => {
        dispatch({ type: actions.SUCCESS_ALERT, data });
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminProductDetails);
