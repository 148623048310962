import { ic_person_outline, ic_group, ic_card_giftcard, ic_lock_outline, ic_video_library } from "react-icons-kit/md";
import { cube, percent } from "react-icons-kit/fa";

const superSideNavData = [
    {
        label: "Home",
        icon: ic_person_outline,
        link: "/super-home",
    },
    {
        label: "ECP Management",
        icon: ic_group,
        link: "/super-ecp-dashboard",
    },
    {
        label: "Product Lists",
        icon: cube,
        link: "/super-prod-dashboard",
    },
    {
        label: "Media Management",
        icon: ic_video_library,
        link: "/super-media-dashboard",
    },
    {
        label: "Rewards Management",
        icon: percent,
        link: "/super-rewards-dashboard",
    },
    {
        label: "Redemptions",
        icon: ic_card_giftcard,
        link: "/super-redemptions-dashboard",
    },
    {
        label: "Logout",
        icon: ic_lock_outline,
        link: "/super-admin",
    },
];

export { superSideNavData };
