import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import { authExist, forceResetPass } from "common/helper/utilities";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    const userProfileExist = rest.userProfile.auth;
    const token = userProfileExist ? rest.userProfile.auth.token : null;
    return <Route {...rest} render={(props) => (authExist(token) && !forceResetPass(token) && restricted ? <Redirect to="/adminhome" /> : <Component {...props} />)} />;
};

const mapStateToProps = (state) => {
    return {
        userProfile: state.userProfile,
    };
};

export default connect(mapStateToProps, null)(PublicRoute);
