import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "reactstrap";
import Icon from "react-icons-kit";
import { pencil } from "react-icons-kit/typicons";
import { trashA, chevronLeft } from "react-icons-kit/ionicons/";
import Dropzone from "react-dropzone";
import moment from "moment";

import { toBase64 } from "common/helper/utilities";
import { HomeLayout, Button, Title } from "components";
import { actions } from "../../../../redux/actions/actions";
import { super_Api_Req } from "common/services";

const initialState = {
    form: {
        date: new Date(),
    },
    bannerLists: [],
};

class SuperAdminAddPromotion extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onDrop = async (file) => {
        const fileBase64 = await toBase64(file[0]);
        console.log(file);
        this.setState((prevState) => ({
            ...prevState,
            form: {
                ...prevState.form,
                image: fileBase64,
                name: file[0].name,
            },
        }));
    };

    onDeletePromo = (data) => {
        const payload = {
            BannerId: data.bannerId,
        };
        super_Api_Req.put
            .deleteBanner(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    onSubmit = () => {
        const { form } = this.state;
        console.log("RUN", form);

        const payload = {
            Image: form.image,
            StartDateUTC: moment(form.date).format("YYYY-MM-DD"),
            EndDateUTC: moment(form.date).add("years", 1).format("YYYY-MM-DD"),
            Points: 1,
        };
        super_Api_Req.post
            .postBanner(payload)
            .then((resp) => {
                this.props.alertSuccess(resp.data.message);
                this.props.fetchSuperSummary();
                this.setState(initialState);
            })
            .catch((err) => {
                this.props.alertError(err);
            });
    };

    onEditPromo = (data) => {
        const { history } = this.props;
        history.push("super-edit-promo", { data: data });
    };

    render() {
        const { form } = this.state;
        const {
            superSummary: { banners },
        } = this.props;
        return (
            <HomeLayout role="super-admin">
                <div className="super-container s-add-promo-container">
                    <div className="title-container">
                        <Title title="ADD PROMOTION" />
                        <div className="back-btn-container" onClick={this.props.history.goBack}>
                            <Icon icon={chevronLeft} size={10} />
                            <p>BACK</p>
                        </div>
                    </div>

                    <Card className="drop-file-zone">
                        <Dropzone onDrop={this.onDrop}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()} className="drop-file">
                                        <p className="desc">{form.name ? form.name : `DROP IMAGES here`}</p>
                                        <p className="desc">- or -</p>
                                        <input {...getInputProps()} accept="image/*" />
                                        <Button title={form.image ? "REUPLOAD FILE" : "SELECT FILE"} />
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Card>
                    <Card>
                        <ul className="banner-list-container">
                            {banners.map((o, i) => {
                                const filename = o.image.replace(/^.*[\\/]/, "").split(".");
                                return (
                                    <li className="banner-item" key={`${i}_${filename[0]}`}>
                                        <div
                                            className="thumbnail"
                                            style={{
                                                backgroundImage: `url(${o.image})`,
                                            }}
                                        />
                                        <p className="desc">{filename[0]}</p>
                                        <div className="promo-controller">
                                            <Icon icon={pencil} size={18} onClick={() => this.onEditPromo(o)} />
                                            <Icon icon={trashA} size={18} onClick={() => this.onDeletePromo(o)} />
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </Card>
                    <div className="button-container">
                        <Button title="SAVE" onClick={this.onSubmit} disabled={!form.image} />
                        <Button title="CANCEL" outline onClick={() => this.props.history.push("super-home")} />
                    </div>
                </div>
            </HomeLayout>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        superSummary: state.superSummary,
    };
};

const mapDispatchToProps = (dispatch) => ({
    alertError: (data) => dispatch({ type: actions.ERROR_ALERT, data }),
    alertSuccess: (data) => dispatch({ type: actions.SUCCESS_ALERT, data }),
    fetchSuperSummary: () => dispatch({ type: actions.FETCH_SUPER_SUMMARY }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SuperAdminAddPromotion);
