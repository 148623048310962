import { actions } from "../actions/actions";

const initialState = {
    banners: [],
    csv: [],
    news: [],
};

export const superSummaryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.INIT_SUPER_SUMMARY:
            return initialState;
        case actions.SET_SUPER_SUMMARY:
            return {
                ...state,
                news: [...action.data.newsList],
                csv: [...action.data.csvList],
                banners: [...action.data.bannerList],
            };
        default:
            return state;
    }
};
