import { api_URL, api_Super_URL, axios_Services } from "./";

const api_Req = {
    post: {
        login: (payload) => {
            const resp = axios_Services.post(api_URL.LOGIN, payload);
            return resp;
        },
        contactUs: (payload) => {
            const resp = axios_Services.post(api_URL.CONTACT_US, payload);
            return resp;
        },
        logout: () => {
            const resp = axios_Services.post(api_URL.LOGOUT);
            return resp;
        },
        forgotPassword: (payload) => {
            const resp = axios_Services.post(api_URL.FORGOT_PASSWORD, payload);
            return resp;
        },
        apptTimeSlot: (payload) => {
            const resp = axios_Services.post(api_URL.APPT_TIME_SLOT, payload);
            return resp;
        },
        setAppt: (payload) => {
            const resp = axios_Services.post(api_URL.SET_APPT, payload);
            return resp;
        },
        cancelAppt: (payload) => {
            const resp = axios_Services.post(api_URL.CANCEL_APPT, payload);
            return resp;
        },
        setApptWarranty: (payload) => {
            const resp = axios_Services.post(api_URL.SET_APPT_WARRANTY, payload);
            return resp;
        },
    },
    get: {
        profile: () => {
            const resp = axios_Services.get(api_URL.PROFILE);
            return resp;
        },
        upcomingAppts: () => {
            const resp = axios_Services.get(api_URL.UPCOMING_APPTS);
            return resp;
        },
        apptOrderList: () => {
            const resp = axios_Services.get(api_URL.APPT_ORDER_LIST);
            return resp;
        },
        apptDetails: (orderNo, apptID) => {
            const resp = axios_Services.get(`${api_URL.GET_APPT_DETAILS}/${orderNo}/${apptID}`);
            return resp;
        },
        editApptDetails: (apptID) => {
            const resp = axios_Services.get(`${api_URL.GET_APPT_DETAILS}/${apptID}`);
            return resp;
        },
        apptRecord: (month, year) => {
            const resp = axios_Services.get(`${api_URL.GET_APPT_RECORD}/${year}/${month}`);
            return resp;
        },
        clientList: () => {
            const resp = axios_Services.get(api_URL.CLIENT_LIST);
            return resp;
        },
        latestNews: () => {
            const resp = axios_Services.get(api_URL.LATEST_NEWS);
            return resp;
        },
        clientDetails: (orderNumber) => {
            const resp = axios_Services.get(`${api_URL.CLIENT_DETAILS}/${orderNumber}`);
            return resp;
        },
        notificationList: () => {
            const resp = axios_Services.get(api_URL.NOTIFICATION_LIST);
            return resp;
        },
    },
    put: {
        seenNotification: (notiID) => {
            const resp = axios_Services.put(`${api_URL.NOTIFICATION_SEEN}/${notiID}`);
            return resp;
        },
        updateProfile: (payload) => {
            const resp = axios_Services.put(api_URL.UPDATE_PROFILE, payload);
            return resp;
        },
        forceResetPassword: (payload) => {
            const resp = axios_Services.put(`${api_URL.FORCE_RESET_PASSWORD}`, payload);
            return resp;
        },
        editAppt: (payload) => {
            const resp = axios_Services.put(`${api_URL.EDIT_APPT_DETAILS}`, payload);
            return resp;
        },
    },
    delete: {},
};

const super_Api_Req = {
    post: {
        login: (payload) => {
            const resp = axios_Services.post(api_Super_URL.LOGIN, payload);
            return resp;
        },
        addNews: (payload) => {
            const resp = axios_Services.post(api_Super_URL.ADD_NEWS, payload);
            return resp;
        },
        addCsv: (payload) => {
            const config = { "Content-Type": "multipart/form-data" };
            const resp = axios_Services.post(api_Super_URL.ADD_CSV, payload, config);
            return resp;
        },
        postBanner: (payload) => {
            const resp = axios_Services.post(api_Super_URL.POST_BANNER, payload);
            return resp;
        },
        addRewards: (payload) => {
            const resp = axios_Services.post(api_Super_URL.ADD_REWARDS, payload);
            return resp;
        },
        addArticle: (payload) => {
            const config = { "Content-Type": "multipart/form-data" };
            const resp = axios_Services.post(api_Super_URL.ADD_ARTICLE, payload, config);
            return resp;
        },
        editArticle: (payload) => {
            const config = { "Content-Type": "multipart/form-data" };
            const resp = axios_Services.post(api_Super_URL.EDIT_ARTICLE, payload, config);
            return resp;
        },
    },
    get: {
        summaryHome: () => {
            const resp = axios_Services.get(api_Super_URL.SUMMARY_HOME);
            return resp;
        },
        notification: () => {
            const resp = axios_Services.get(api_Super_URL.NOTIFICATION);
            return resp;
        },
        ecpLists: () => {
            const resp = axios_Services.get(api_Super_URL.ECP_LIST);
            return resp;
        },
        ecpDetails: (storeId) => {
            const resp = axios_Services.get(`${api_Super_URL.ECP_Details}${storeId}`);
            return resp;
        },
        ecpInactiveLists: () => {
            const resp = axios_Services.get(api_Super_URL.ADD_ECP_LIST);
            return resp;
        },
        rewardsLists: () => {
            const resp = axios_Services.get(api_Super_URL.REWARDS_LISTS);
            return resp;
        },
        productLists: () => {
            const resp = axios_Services.get(api_Super_URL.PRODUCT_LIST);
            return resp;
        },

        productDetails: (id) => {
            const resp = axios_Services.get(`${api_Super_URL.PRODUCT_DETAILS}/${id}`);
            return resp;
        },
        redemptionsList: () => {
            const resp = axios_Services.get(api_Super_URL.REDEMPTIONS_LIST);
            return resp;
        },
        redemptionsDetails: (id) => {
            const resp = axios_Services.get(`${api_Super_URL.REDEMPTIONS_DETAILS}/${id}`);
            return resp;
        },
        articleList: (type, length) => {
            const resp = axios_Services.get(`${api_Super_URL.ARTICLE_LIST}?type=${type}&length=${length}`);
            return resp;
        },
        articleDetails: (id) => {
            const resp = axios_Services.get(`${api_Super_URL.ARTICLE_DETAILS}/${id}`);
            return resp;
        },
    },
    put: {
        newsVisibility: (payload) => {
            const resp = axios_Services.put(api_Super_URL.NEWS_VISIBILITY, payload);
            return resp;
        },
        editRewards: (payload) => {
            const resp = axios_Services.put(api_Super_URL.EDIT_REWARDS, payload);
            return resp;
        },
        deleteRewards: (payload) => {
            const resp = axios_Services.put(api_Super_URL.DELETE_REWARDS, payload);
            return resp;
        },
        ecpDetails: (payload) => {
            const resp = axios_Services.put(api_Super_URL.UPDATE_ECP_DETAILS, payload);
            return resp;
        },
        addECP: (payload) => {
            const resp = axios_Services.put(api_Super_URL.ADD_ECP, payload);
            return resp;
        },
        editBanner: (payload) => {
            const resp = axios_Services.put(api_Super_URL.EDIT_BANNER, payload);
            return resp;
        },
        redeemUpdate: (payload) => {
            const resp = axios_Services.put(api_Super_URL.REDEEM_UPDATE, payload);
            return resp;
        },
        deleteBanner: (payload) => {
            const resp = axios_Services.put(api_Super_URL.DELETE_BANNER, payload);
            return resp;
        },
        updatePoints: (payload) => {
            const resp = axios_Services.put(api_Super_URL.UPDATE_POINTS, payload);
            return resp;
        },
    },
    delete: {},
};

export { api_Req, super_Api_Req };
