import React, { Component } from "react";
import { ic_add_box } from "react-icons-kit/md/ic_add_box";
import { Card, CardBody, Label } from "reactstrap";
import moment from "moment";
import Icon from "react-icons-kit";
import { withRouter } from "react-router-dom";

class ClientCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientDetails: {},
        };
    }

    timeSlot = (slot) => {
        return slot === 1 ? "11AM - 1PM" : slot === 2 ? "3PM - 5PM" : "7PM - 9PM";
    };

    formatDate = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };

    navigateAppt = (data, expiredStatus, requestTime) => {
        const { history, clientDetails } = this.props;
        data.orderNumber = clientDetails.orderNumber;
        if (data.status === "3" || expiredStatus) {
            history.push("appointmenthistory", { apptData: data });
        } else {
            data.requestTime = requestTime;
            history.push("editappointment", { apptData: data });
        }
    };

    render() {
        const {
            clientDetails: { userInfo, profileInfo, rightResult, leftResult, orderNumber, dateOfPurchase, warrantyValidUntil, isReplacementPair },
        } = this.props;
        console.log(profileInfo);
        if (profileInfo) {
            return (
                <div className="card_container">
                    <Card>
                        <CardBody>
                            <div className="cardhalf_container">
                                <Label>ORDER NUMBER</Label>
                                <div>
                                    <p> {orderNumber} </p>
                                </div>
                                <Label>NAME OF OWNER</Label>
                                <div>
                                    <p> {profileInfo.profileName} </p>
                                </div>
                                <Label>DATE OF BIRTH</Label>
                                <div>
                                    <p> {this.formatDate(profileInfo.dateOfBirth)} </p>
                                </div>
                                <div>
                                    <div>
                                        <Label>DATE OF PURCHASE</Label>
                                        <p> {this.formatDate(dateOfPurchase)} </p>
                                    </div>
                                    <div>
                                        <Label>END OF WARRANTY</Label>
                                        <p>{isReplacementPair || warrantyValidUntil === undefined || !warrantyValidUntil ? "-" : this.formatDate(warrantyValidUntil)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="half_container">
                                <Label>RL TYPE</Label>
                                <p>Right</p>
                                <Label>SPH</Label>
                                <p>{rightResult.sph}</p>
                                <Label>CYL</Label>
                                <p>{rightResult.cyl}</p>
                                <Label>AXIS</Label>
                                <p>0</p>
                            </div>
                            <div className="minhalf_container">
                                <Label>RL TYPE</Label>
                                <p>Left</p>
                                <Label>SPH</Label>
                                <p>{leftResult.sph}</p>
                                <Label>CYL</Label>
                                <p>{leftResult.cyl}</p>
                                <Label>AXIS</Label>
                                <p>0</p>
                            </div>
                        </CardBody>
                        <div className="contact_container">
                            <div className="contact_name">
                                <Label>CONTACT NAME</Label>
                                <p>{userInfo.userName}</p>
                            </div>
                            <div className="contact_number">
                                <Label>CONTACT NUMBER</Label>
                                <p>{userInfo.mobileNumber}</p>
                            </div>
                        </div>
                        <div className="contact_container">
                            <div className="contact_content">
                                <Label>ADDRESS</Label>
                                <p>{userInfo.shippingAddress}</p>
                            </div>
                        </div>
                        <div className="appointment_container">
                            <div>
                                <Icon icon={ic_add_box} onClick={() => this.props.history.push("appointmentcalendar")} />
                                <Label>APPOINTMENT</Label>
                                <ol>
                                    {profileInfo.appointmentList &&
                                        profileInfo.appointmentList.map((data, i) => {
                                            const today = moment();
                                            const expired = today.diff(data.appointmentDate, "days");
                                            const expiredStatus = data.status === "2" && expired > 0;
                                            const status = data.status === "1" ? "PENDING" : expiredStatus ? "EXPIRED" : data.status === "2" ? "UPCOMING" : "COMPLETED";
                                            const time = this.timeSlot(data.slot);
                                            const requestSlot = this.timeSlot(data.requestedSlot);
                                            const apptDate = this.formatDate(data.appointmentDate);
                                            const requestDate = this.formatDate(data.requestedDate);
                                            const requestedChanged = data.status === "1";
                                            const requestTime = requestedChanged ? `${requestDate}, ${requestSlot} REQUESTED` : "";
                                            const isUpCommingClassName = expiredStatus ? "" : "is-upcomming";

                                            return (
                                                <li key={`${data.appointmentDate}_${i}`} className={isUpCommingClassName} onClick={() => this.navigateAppt(data, expiredStatus, requestTime)}>
                                                    {`${apptDate}, ${time} - ${status}`}
                                                    <span className="request-slot">{`${requestTime}`}</span>
                                                </li>
                                            );
                                        })}
                                </ol>
                            </div>
                        </div>
                    </Card>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default withRouter(ClientCardComponent);
