export const tHeader = {
    videoList: [
        {
            label: "THUMBNAILS",
            key: "thumbnail",
            format: "img",
        },
        {
            label: "TITLE",
            key: "title",
        },
        {
            label: "POSTED DATE",
            key: "updatedAtUTC",
            format: "date",
        },
        {
            label: "",
            key: "edit",
        },
        {
            label: "",
            key: "delete",
        },
    ],
    articleList: [
        {
            label: "POSTED DATE",
            key: "updatedAtUTC",
            format: "date",
        },
        {
            label: "TITLE",
            key: "title",
        },
        {
            label: "",
            key: "edit",
        },
        {
            label: "",
            key: "delete",
        },
    ],
};
