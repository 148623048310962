const miyosmart_url = process.env.REACT_APP_MIYOSMART_URL;
const super_admin_miyosmart_url = process.env.REACT_APP_SA_MIYOSMART_URL;

const api_URL = {
    /* LOGIN & LOGOUT*/
    LOGIN: miyosmart_url + "Account/Login",
    LOGOUT: miyosmart_url + "Account/Logout",
    /* PROFILE & UPDATE PROFILE*/
    PROFILE: miyosmart_url + "Profile",
    UPDATE_PROFILE: miyosmart_url + "Profile",
    /* CONTACT US */
    CONTACT_US: miyosmart_url + "Contact",
    /* NOTIFICATION & SEEN*/
    NOTIFICATION_LIST: miyosmart_url + "Notification",
    NOTIFICATION_SEEN: miyosmart_url + "Notification",
    /* UPCOMING APPTS & LATEST_NEWS */
    UPCOMING_APPTS: miyosmart_url,
    LATEST_NEWS: miyosmart_url + "News",
    APPT_ORDER_LIST: miyosmart_url + "Appointment/OrderList",
    APPT_TIME_SLOT: miyosmart_url + "Appointment/TimeSlot",
    SET_APPT: miyosmart_url + "Appointment",
    EDIT_APPT_DETAILS: miyosmart_url + "Appointment",
    CANCEL_APPT: miyosmart_url + "Appointment/Cancel",
    SET_APPT_WARRANTY: miyosmart_url + "Appointment/Warranty",
    GET_APPT_DETAILS: miyosmart_url + "Appointment",
    GET_APPT_RECORD: miyosmart_url + "AppointmentRecord",
    /* CLIENT LIST & CLIENT DETAILS*/
    CLIENT_LIST: miyosmart_url + "Clients",
    CLIENT_DETAILS: miyosmart_url + "Clients",
    /* RESET PASSWORD */
    FORCE_RESET_PASSWORD: miyosmart_url + "Account/ForceResetPassword",
    FORGOT_PASSWORD: miyosmart_url + "/Account/Forget",
};

const api_Super_URL = {
    LOGIN: super_admin_miyosmart_url + "Account/Login",
    SUMMARY_HOME: miyosmart_url,
    /* NOTIFICATION */
    NOTIFICATION: miyosmart_url + "SANotification",
    /* NEWS */
    NEWS_VISIBILITY: miyosmart_url + "News",
    ADD_NEWS: miyosmart_url + "News",
    /* BANNER */
    POST_BANNER: miyosmart_url + "Banner",
    EDIT_BANNER: miyosmart_url + "Banner",
    DELETE_BANNER: miyosmart_url + "Banner/Deactivate",
    /* STORE */
    ECP_LIST: miyosmart_url + "Store",
    UPDATE_ECP_DETAILS: miyosmart_url + "StoreUpdate",
    ADD_ECP_LIST: miyosmart_url + "GetInactiveECP",
    ECP_Details: miyosmart_url + "Store/",
    ADD_ECP: miyosmart_url + "StoreUpdate",
    /* REDEMPTION */
    REWARDS_LISTS: miyosmart_url + "Redemption",
    ADD_REWARDS: miyosmart_url + "Redemption",
    EDIT_REWARDS: miyosmart_url + "Redemption",
    DELETE_REWARDS: miyosmart_url + "Redemption/Deactivate",
    /* PRODUCT */
    PRODUCT_LIST: miyosmart_url + "Product",
    REDEMPTIONS_LIST: miyosmart_url + "Redeem",
    REDEMPTIONS_DETAILS: miyosmart_url + "Redeem",
    PRODUCT_DETAILS: miyosmart_url + "Product",
    ADD_CSV: miyosmart_url + "Product",
    REDEEM_UPDATE: miyosmart_url + "Redeem/Update",
    UPDATE_POINTS: miyosmart_url + "Product/UpdatePoints",
    /*ARTICLE*/
    ARTICLE_LIST: miyosmart_url + "Article",
    ARTICLE_DETAILS: miyosmart_url + "Article/",
    ADD_ARTICLE: miyosmart_url + "Article",
    EDIT_ARTICLE: miyosmart_url + "Article/Update",
};

const timeSlotData = [
    {
        label: "9AM - 11AM",
        value: { start: 9, end: 11 },
        index: 0,
    },
    {
        label: "11AM - 1PM",
        value: { start: 11, end: 13 },
        index: 1,
    },
    {
        label: "1PM - 3PM",
        value: { start: 13, end: 15 },
        index: 2,
    },
    {
        label: "3PM - 5PM",
        value: { start: 15, end: 17 },
        index: 3,
    },
    {
        label: "5PM - 7PM",
        value: { start: 17, end: 19 },
        index: 4,
    },
    {
        label: "7PM - 9PM",
        value: { start: 19, end: 21 },
        index: 5,
    },
];

export { api_Super_URL, api_URL, timeSlotData };
