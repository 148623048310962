import React, { PureComponent } from "react";

export default class Button extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { type, title, disabled, outline, onClick, className } = this.props;
        const outlineS = outline ? "outline" : null;
        return (
            <button type={type} className={["miyosmart-button", outlineS, className].join(" ")} disabled={disabled} onClick={onClick}>
                {title}
            </button>
        );
    }
}
